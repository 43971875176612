import { observable, reaction } from "mobx";
import { useEffect } from "react";
import usePosition from "../../hooks/position";
import i18n from "../../i18n";
import { getCachedResolver } from "../helpers/cache";
import { Container } from "../helpers/container";
import { PromptStore } from "../prompt_store";
import { Vehicle } from "./vehicle";
import { VehiclesService } from "./vehicles_service";

export class VehiclesStore {
  @observable public all: Vehicle[] = [];
  @observable public search = "";
  @observable public isLoading = false;

  constructor(
    container: Container,
    private readonly _prompt = container.get(PromptStore),
    private readonly _service = container.get(VehiclesService)
  ) {}

  public useFiltered = (): void => {
    const position = usePosition();

    useEffect(() => {
      this.update(this.search, position);

      return reaction(
        () => this.search,
        (search) => this.update(search, position)
      );
    }, [position]);
  };

  public async reload(): Promise<void> {
    this.all = [];
    await this.update(this.search);
  }

  private update = getCachedResolver(
    async (search: string, position?: Position) => {
      try {
        this.all = await this._service.findVehicles(search, position);
      } catch (ex) {
        console.error(ex);
        this._prompt.showErrorAlert(
          i18n.t("vehicles.errors.load_failed.title"),
          i18n.t("vehicles.errors.load_failed.subtitle"),
          ex
        );
      }
    }
  );
}
