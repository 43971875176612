import { observable } from "mobx";
import { useEffect, useState } from "react";
import config from "../../config";
import { Api } from "../api";
import { AuthStore } from "../auth_store";
import { Container } from "../helpers/container";
import IExceptionEvent from "../rpc/models/exception_event";
import { log } from "../utils/log";
import ScoreService from "./score_service";
import StatusStore from "./status_store";

interface IScoreConfig {
  limits: [number, number][];
  seconds: number;
}

export default class ScoreStore {
  @observable public image = "";
  @observable public value = 0;
  @observable public config: IScoreConfig | null = null;
  @observable public isOffline = false;
  @observable public exceptions: IExceptionEvent[] = [];

  constructor(
    container: Container,
    private readonly _api = container.get(Api),
    private readonly _auth = container.get(AuthStore),
    private readonly _score = container.get(ScoreService),
    private readonly _status = container.get(StatusStore)
  ) {
    this.value = 1;
    this.image = config.score.faces.replace("{{score}}", this.value + "");
  }

  public useScore(): [number, boolean, IExceptionEvent[]] {
    const [score, setScore] = useState(1);
    const [events, setEvents] = useState<IExceptionEvent[]>([]);
    const [isOffline, setIsOffline] = useState(false);

    useEffect(() => {
      const interval = setInterval(() => {
        if (!this._auth.me) return;
        if (!this._status.vehicle) return;

        this._score
          .getScore(
            this._auth.me.user,
            new Date(Date.now() - this._score.interval),
            new Date()
          )
          .then(([score, events]) => {
            setScore(score);
            setEvents(events);
            setIsOffline(false);
          })
          .catch((ex) => {
            log.error(ex);
            setIsOffline(true);
          });
      }, 10_000);

      return () => clearInterval(interval);
    }, [this._status.vehicle]);

    return [score, isOffline, events];
  }
}
