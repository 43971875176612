import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../core/vehicles/vehicle";

export default function VehicleItemHeader(props: {
  vehicle: Vehicle;
}): JSX.Element {
  const [t] = useTranslation();

  return useObserver(() => {
    const name = props.vehicle.device.name ?? t("unknown");
    return <h3>{name}</h3>;
  });
}
