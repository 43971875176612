import { observable } from "mobx";
import { Api } from "../api";
import { Driver } from "../drivers/driver";
import { DriversStore } from "../drivers/drivers_store";
import { getCachedResolver } from "../helpers/cache";
import IDevice from "../rpc/models/device";
import IDeviceStatusInfo from "../rpc/models/device_status_info";
import IDriverChange from "../rpc/models/driver_change";
import IStatusData from "../rpc/models/status_data";
import { first, last } from "../utils/array";
import getDistance from "../utils/distance";

type StatusType = "DiagnosticIgnitionId";

export enum DriverStatus {
  Pending,
  Assigned,
}

export class Vehicle {
  @observable
  public distance: number | null = null;

  constructor(
    public readonly device: IDevice,
    public readonly status: IDeviceStatusInfo | null,
    public readonly position: Position | null,
    private readonly _api: Api,
    private readonly _drivers: DriversStore
  ) {
    this.distance =
      status && position ? getDistance(status, position.coords) : null;
  }

  public async getDriver(): Promise<Driver | null> {
    const change = first(
      await this._api.find("DriverChange", {
        deviceSearch: { id: this.device.id },
        includeOverlappedChanges: true,
      })
    );

    if (!change) return null;
    if (typeof change.driver === "string") return null;

    return this._drivers.getDriver(change.driver.id);
  }

  public getIsIgnitionOn = getCachedResolver(async () => {
    const fromDate = new Date(Date.now() - 1000 * 60 * 60 * 24);
    const ignitionLogs = await this.getDiagnostics(
      "DiagnosticIgnitionId",
      fromDate
    );

    if (ignitionLogs.length === 0) {
      return false;
    }

    const ignitionLog = last(ignitionLogs);
    return ignitionLog?.data === 1;
  });

  public async getLatestDriverChange(): Promise<IDriverChange | undefined> {
    return (await this.getDriverChanges())[0];
  }

  public getDriverChanges = getCachedResolver(
    async (fromDate?: Date) =>
      await this._api.find("DriverChange", {
        fromDate: fromDate?.toISOString(),
        deviceSearch: { id: this.device.id },
        includeOverlappedChanges: true,
      })
  );

  public getDiagnostics = getCachedResolver(
    async (type: StatusType, fromDate?: Date, limit?: number) =>
      await this._api.find(
        "StatusData",
        {
          fromDate: fromDate?.toISOString(),
          deviceSearch: this.device,
          diagnosticSearch: { id: type },
        },
        limit
      )
  );

  private async getChangesAndIgntions(
    fromDate: Date,
    driver?: Driver
  ): Promise<[IDriverChange[], IStatusData[]]> {
    type Result = [IDriverChange[], IStatusData[], IDriverChange[] | null];

    const result: Result = await this._api.batch((factory) => {
      const find = [
        factory.find("DriverChange", {
          fromDate,
          deviceSearch: { id: this.device.id },
        }),
        factory.find("StatusData", {
          fromDate,
          deviceSearch: { id: this.device.id },
          diagnosticSearch: { id: "DiagnosticIgnitionId" },
        }),
      ];

      if (driver) {
        find.push(
          factory.find("DriverChange", {
            userSearch: { id: driver.user.id },
            includeOverlappedChanges: true,
          })
        );
      }

      return find;
    });

    const changes = result[0].concat(result[2] ?? []);
    const ignitions = result[1];

    if (result[2]) {
      const fromDate = first(changes)?.dateTime;
      if (fromDate) {
        ignitions.concat(
          await this._api.find("StatusData", {
            fromDate,
            deviceSearch: { id: this.device.id },
            diagnosticSearch: { id: "DiagnosticIgnitionId" },
          })
        );
      }
    }

    return [changes, ignitions];
  }

  private wasOff<TEvent extends { dateTime: any }>(
    ignitions: IStatusData[],
    from?: TEvent,
    to?: TEvent
  ) {
    const toDate = to ? new Date(to.dateTime) : null;
    const fromDate = from ? new Date(from.dateTime) : null;

    for (const ignition of ignitions) {
      const ignitionDate = new Date(ignition.dateTime);
      if (fromDate && fromDate > ignitionDate) continue;
      if (toDate && toDate < ignitionDate) continue;
      if (ignition.data === 0) return true;
    }

    return false;
  }
}
