import { menuController } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowForwardOutline,
  informationCircleOutline,
  libraryOutline,
  newspaperOutline,
} from "ionicons/icons";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import pkg from "../../../package.json";
import openExternal from "../../core/utils/external";
import { useApp } from "../../hooks/app_store";
import DatabaseMenuItem from "./database_item";
import LogoutItem from "./logout_item";

export const SETTINGS_MENU_ID = "settingsMenu";

export default function SettingsMenu(): JSX.Element {
  const { auth } = useApp();
  const [t] = useTranslation();
  const history = useHistory();
  return useObserver(() => (
    <IonMenu side="end" contentId="main" menuId={SETTINGS_MENU_ID}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("settings.title")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => menuController.close()}>
              <IonIcon icon={arrowForwardOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DatabaseMenuItem />

          <IonItem
            onClick={() => openExternal("http://eco.fairfleet.de/eco-news/")}
          >
            <IonLabel>
              <h2>{t("settings.news.title")}</h2>
              <p>{t("settings.news.subtitle")}</p>
            </IonLabel>
            <IonIcon icon={newspaperOutline} slot="end" />
          </IonItem>

          <IonItem
            onClick={() => openExternal("http://eco.fairfleet.de/impressum/")}
          >
            <IonLabel>
              <h2>{t("settings.about.title")}</h2>
              <p>{t("settings.about.subtitle")}</p>
            </IonLabel>
            <IonIcon icon={informationCircleOutline} slot="end" />
          </IonItem>

          <IonItem
            onClick={() => {
              history.push("/licenses");
              menuController.close();
            }}
          >
            <IonLabel>
              <h2>{t("settings.licenses.title")}</h2>
              <p>{t("settings.licenses.subtitle")}</p>
            </IonLabel>
            <IonIcon icon={libraryOutline} slot="end" />
          </IonItem>

          <div style={{ flexGrow: 1 }} />

          {auth.me && (
            <IonItem>
              <IonLabel>
                <h2>{t("login.username")}</h2>
                <p>{auth.me.user.name}</p>
              </IonLabel>
            </IonItem>
          )}

          <IonItem>
            <IonLabel>
              <h2>{t("settings.version.title")}</h2>
              <p>{pkg.version}</p>
            </IonLabel>
          </IonItem>

          <LogoutItem
            onClick={() => {
              menuController.close();
            }}
          />
        </IonList>
      </IonContent>
    </IonMenu>
  ));
}
