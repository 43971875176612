import { useEffect, useState } from "react";
import { Geolocation } from "@ionic-native/geolocation";

let offset_set = false;
let offset_latitude = 0;
let offset_longitude = 0;

export default function usePosition(): Position | undefined {
  const [position, setPosition] = useState<Position>();

  useEffect(() => {
    const watch = Geolocation.watchPosition();
    const subscriber = watch.subscribe((updated) => {
      if (!("coords" in updated)) {
        return;
      }

      if (!offset_set && process.env.NODE_ENV?.startsWith("dev")) {
        offset_longitude = updated.coords.longitude - 6.5866412;
        offset_latitude = updated.coords.latitude - 51.12333;
        offset_set = true;
      }

      if (
        position &&
        position.coords.latitude - updated.coords.latitude < 1000 &&
        position.coords.longitude - updated.coords.longitude < 1000
      ) {
        return;
      }

      const calculated = {
        ...updated,
        coords: {
          ...updated.coords,
          latitude: updated.coords.latitude - offset_latitude,
          longitude: updated.coords.longitude - offset_longitude,
        },
      };

      if (isNaN(calculated.coords.latitude) || isNaN(calculated.coords.longitude)) {
        setPosition(undefined);
      } else {
        setPosition(calculated);
      }
    });

    return () => subscriber.unsubscribe();
  });

  return position;
}
