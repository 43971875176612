import { IonAlert } from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useApp } from "../../../hooks/app_store";

export function Alert(): JSX.Element {
  const { prompt } = useApp();
  return useObserver(() => (
    <IonAlert
      isOpen={prompt.alert.isOpen}
      header={prompt.alert.title}
      message={prompt.alert.message}
      buttons={prompt.alert.buttons}
      onDidDismiss={() => (prompt.alert.isOpen = false)}
    />
  ));
}
