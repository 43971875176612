import { AppVersion } from "@ionic-native/app-version";
import { isPlatform } from "@ionic/react";
import { observable } from "mobx";
import { useEffect, useState } from "react";
import variables from "../variables.json";
import { Api } from "./api";
import { AuthStore } from "./auth_store";
import { DriversStore } from "./drivers/drivers_store";
import GroupsService from "./groups/groups_service";
import { Container } from "./helpers/container";
import { PromptStore } from "./prompt_store";
import { RouteStore } from "./routes/route_store";
import { SettingsStore } from "./settings/settings_store";
import DriverChangeService from "./trip/driver_change_service";
import ScoreService from "./trip/score_service";
import ScoreStore from "./trip/score_store";
import StatusStore from "./trip/status_store";
import TripSummaryService from "./trip/summary/summary_service";
import TripSummaryStore from "./trip/summary/summary_store";
import { log } from "./utils/log";
import { NfcStore } from "./vehicles/nfc_store";
import { VehiclesService } from "./vehicles/vehicles_service";
import { VehiclesStore } from "./vehicles/vehicles_store";

export class App {
  @observable public readonly api: Api;
  @observable public readonly nfc: NfcStore;
  @observable public readonly auth: AuthStore;
  @observable public readonly score: ScoreStore;
  @observable public readonly status: StatusStore;
  @observable public readonly route: RouteStore;
  @observable public readonly prompt: PromptStore;
  @observable public readonly drivers: DriversStore;
  @observable public readonly settings: SettingsStore;
  @observable public readonly vehicles: VehiclesStore;

  @observable public readonly tripSummary: TripSummaryStore;

  constructor() {
    const container = new Container()
      .register(App, this)
      .register(Api)
      .register(NfcStore)
      .register(AuthStore)
      .register(GroupsService)

      .register(ScoreStore)
      .register(ScoreService)

      .register(TripSummaryStore)
      .register(TripSummaryService)

      .register(StatusStore)
      .register(RouteStore)
      .register(PromptStore)
      .register(DriversStore)
      .register(SettingsStore)
      .register(VehiclesStore)
      .register(VehiclesService)
      .register(DriverChangeService);

    this.api = container.get(Api);
    this.nfc = container.get(NfcStore);
    this.auth = container.get(AuthStore);

    this.score = container.get(ScoreStore);
    this.status = container.get(StatusStore);

    this.route = container.get(RouteStore);
    this.prompt = container.get(PromptStore);
    this.drivers = container.get(DriversStore);
    this.settings = container.get(SettingsStore);
    this.vehicles = container.get(VehiclesStore);

    this.tripSummary = container.get(TripSummaryStore);
  }

  public useIsWebOutOfDate(): boolean {
    const [isOutdated, setOutdated] = useState(false);
    const getIsOutdated = () =>
      fetch("index.html", { cache: "no-store" })
        .then((remote) => remote.text())
        .then((remote) => {
          const remoteParser = new DOMParser();
          const remoteDom = remoteParser.parseFromString(remote, "text/html");
          const remoteVersion =
            remoteDom
              .querySelector("meta[name=version]")
              ?.getAttribute("content") ?? "";

          const currentVersion =
            document.head
              .querySelector("meta[name=version]")
              ?.getAttribute("content") ?? "";

          log.debug("App.useIsWebOutOfDate", { remoteVersion, currentVersion });
          return remoteVersion !== currentVersion;
        });

    useEffect(() => {
      const handle = setInterval(
        () =>
          getIsOutdated()
            .then((isOutdated) => setOutdated(isOutdated))
            .catch(log.error),
        60_000
      );

      return () => clearInterval(handle);
    });

    return isOutdated;
  }

  public useIsAppOutOfDate(): boolean {
    const [isOutdated, setOutdated] = useState(false);
    const getVersionCode = (): number =>
      variables.minSdkVersion * 10000000 +
      variables.versionMajor * 10000 +
      variables.versionMinor * 100 +
      variables.versionPatch;

    if (isPlatform("ios")) {
      return false;
    }

    const useVersionCode = (current: number) => {
      const expected = getVersionCode();
      setOutdated(current < expected);
    };

    useEffect(() => {
      if (!process.env.NODE_ENV?.startsWith("dev")) {
        AppVersion.getVersionCode()
          .then((current) =>
            typeof current === "string"
              ? useVersionCode(parseFloat(current))
              : useVersionCode(current)
          )
          .catch(() => useVersionCode(Number.MAX_SAFE_INTEGER));
      }
    });

    return isOutdated;
  }
}
