import { IonCardContent, IonGrid } from "@ionic/react";
import React from "react";
import useWakeLock from "../../../hooks/wake_lock";
import Score from "./score";

interface IProps {
  score: number;
  isOffline: boolean;
}

export default function ScoreCard(props: IProps): JSX.Element {
  useWakeLock();

  return (
    <IonGrid
      style={{
        flexGrow: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <IonCardContent style={{ flexGrow: 1, width: "100%", margin: "auto" }}>
        <Score score={props.score} isOffline={props.isOffline} />
      </IonCardContent>
    </IonGrid>
  );
}
