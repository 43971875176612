import { RefresherEventDetail } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonText,
} from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ITripSummary from "../../core/trip/summary/summary";
import TripState from "../../core/trip/trip_state";
import { useApp } from "../../hooks/app_store";
import i18n from "../../i18n";
import EventsCard from "./cards/events_card";
import SummaryDetailsCard from "./cards/summary_details_card";
import "./page_trip_summary.scss";

export default function TripSummaryPage(): JSX.Element {
  const { tripSummary } = useApp();
  const [summary, reload] = tripSummary.use();

  return (
    <IonContent>
      <IonRefresher
        slot="fixed"
        onIonRefresh={(evt: CustomEvent<RefresherEventDetail>) =>
          reload().then(() => evt.detail.complete())
        }
      >
        <IonRefresherContent />
      </IonRefresher>

      {summary ? <SummaryDetails summary={summary} /> : <SummaryEmpty />}

      <SummaryButtons />
    </IonContent>
  );
}

function SummaryDetails(props: { summary: ITripSummary }): JSX.Element {
  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <SummaryDetailsCard summary={props.summary} loading={false} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <EventsCard events={props.summary.events ?? []} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

function SummaryEmpty(): JSX.Element {
  const [t] = useTranslation();

  return (
    <div
      style={{
        top: "50%",
        width: "100%",
        position: "absolute",
        transform: "translateY(-50%)",
      }}
    >
      <IonRow>
        <IonCol size="12">
          <IonIcon
            icon={alertCircleOutline}
            style={{ width: "100%", height: 48 }}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <IonLabel className="ion-text-center">
            <h2>{t("trip_summary.empty.title")}</h2>
            <p>{t("trip_summary.empty.subtitle")}</p>
          </IonLabel>
        </IonCol>
      </IonRow>
    </div>
  );
}

function SummaryButtons(): JSX.Element {
  const { status } = useApp();
  const [t] = useTranslation();
  const history = useHistory();

  if (status.state !== TripState.Active && status.state !== TripState.Complete) {
    return <></>;
  }

  return (
    <>
      <div style={{ height: 140 }} />
      <div className="eco-buttons">
        <IonButton
          expand="block"
          size="large"
          onClick={() => status.continue().then(() => history.push("/trip"))}
        >
          {t("trip.continue")}
        </IonButton>

        <IonButton
          expand="block"
          size="large"
          onClick={() => {
            status.stop(true);
            history.push("/vehicles");
          }}
        >
          {t("trip.stop")}
        </IonButton>
      </div>
    </>
  );
}
