import { menuController } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../hooks/app_store";
import MenuItem from "./menu_item";

export const MAIN_MENU_ID = "mainMenu";

export default function MainMenu(): JSX.Element {
  const [t] = useTranslation();
  const { route } = useApp();

  return useObserver(() => (
    <IonMenu side="start" contentId="main" menuId={MAIN_MENU_ID}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("main.menu")}</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => menuController.close()}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {route.routes.map((route, key) => (
            <MenuItem key={key} route={route} />
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  ));
}
