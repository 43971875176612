type Service = {
  name: string;
  new (container: Container, ...args: any[]): any;
};

type Instance<T> = T extends {
  new (container: Container, ...args: never[]): infer P;
}
  ? P
  : never;

export class Container {
  public get<TService extends Service, TInstance extends Instance<TService>>(
    service: TService
  ): TInstance {
    const def = service as any;
    const key = def.__key;
    const instance = this._instances.get(key);
    if (!instance) {
      const ctor = this._services.get(key);
      if (!ctor) {
        throw new Error(`Service "${service.name}" not registered`);
      }

      const instance = new ctor(this);
      this._instances.set(key, instance);
      return instance;
    }

    return instance;
  }

  public register<
    TService extends Service,
    TInstance extends Instance<TService>
  >(service: TService, instance?: TInstance): Container {
    const key = Symbol("ident");
    const def = service as any;

    def.__key = key;
    this._services.set(key, def);

    if (instance) {
      this._instances.set(key, instance);
    }

    return this;
  }

  private readonly _services = new Map<symbol, Service>();
  private readonly _instances = new Map<symbol, any>();
}
