import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import IExceptionEvent from "../../../core/rpc/models/exception_event";

interface IProps {
  events: IExceptionEvent[];
}

export default function EventsCard(props: IProps): JSX.Element {
  const [t] = useTranslation();

  if (props.events.length === 0) {
    return <></>;
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{t("trip.events.title")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {props.events
          .sort((a, b) => Date.parse(b.activeTo) - Date.parse(a.activeTo))
          .map((event) => (
            <IonItem key={(event as any).id}>
              <IonLabel>
                <h3>{event.rule.name?.replace(/^ECO /, "")}</h3>
                <p>
                  <b>{t("trip.events.date")} </b>
                  {new Date(event.activeTo).toLocaleString()}
                </p>
                <p>
                  <b>{t("trip.events.weight")} </b>
                  {parseFloat(event.rule.comment)}
                </p>
              </IonLabel>
            </IonItem>
          ))}
      </IonCardContent>
    </IonCard>
  );
}
