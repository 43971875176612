import { IonContent } from "@ionic/react";
import React from "react";
import VehicleList from "./list/list";
import VehiclesRefresh from "./list/refresh";
import VehiclesSearch from "./list/search";
import VehiclesSearchButtons from "./list/search_buttons";

export default function VehiclesPage(): JSX.Element {
  return (
    <IonContent>
      <VehiclesRefresh />
      <VehiclesSearchButtons />
      <VehiclesSearch />

      <VehicleList />
    </IonContent>
  );
}
