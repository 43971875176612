import { useEffect, useState } from "react";
import { Insomnia } from "@ionic-native/insomnia";
import { log } from "../core/utils/log";

export default function useWakeLock(): void {
  useEffect(() => {
    let dtor = () => { };

    keepAwake()
      .then(_dtor => dtor = _dtor)
      .catch(log.error);
    
    return dtor;
  });
}

async function keepAwake(): Promise<() => void> {
  let navigator = window.navigator as any;
  if ("wakeLock" in navigator) {
    const sentinel = await navigator.wakeLock.request("screen");
    return sentinel.release;
  }

  await Insomnia.keepAwake();
  return () => Insomnia.allowSleepAgain().catch(log.error);
}
