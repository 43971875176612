import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonText,
} from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function PendingCard(): JSX.Element {
  const [t] = useTranslation();
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{t("trip.pending.title")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonText>
          <p>{t("trip.pending.subtitle")}</p>
        </IonText>
      </IonCardContent>
    </IonCard>
  );
}
