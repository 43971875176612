import { History } from "history";
import { observable } from "mobx";
import i18n from "../i18n";
import { Api } from "./api";
import { Driver } from "./drivers/driver";
import { DriversStore } from "./drivers/drivers_store";
import { Container } from "./helpers/container";
import { PromptStore } from "./prompt_store";
import ILoginResult from "./rpc/models/login_result";
import IUser from "./rpc/models/user";
import { SettingsStore } from "./settings/settings_store";
import { log } from "./utils/log";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const LogRocket = require("logrocket");

export class AuthStore {
  @observable me: Driver | null = null;
  @observable isLoggedIn = false;

  constructor(
    container: Container,
    private readonly _api = container.get(Api),
    private readonly _prompt = container.get(PromptStore),
    private readonly _drivers = container.get(DriversStore),
    private readonly _settings = container.get(SettingsStore)
  ) {}

  public async login(username: string, password: string): Promise<void> {
    const database = this._settings.database;
    const hide = this._prompt.showLoading("Logging in");

    try {
      const result = await this._api.authUser(database, username, password);
      await this.authResult(result);
    } catch (ex) {
      this._api.useLoginResult();
      this._prompt.showErrorAlert(
        i18n.t("login.errors.failed.title"),
        i18n.t("login.errors.failed.subtitle"),
        ex
      );
    } finally {
      hide();
    }
  }

  public async checkSession(): Promise<void> {
    const json = localStorage.getItem("session");
    if (!json) return;

    const result = JSON.parse(json) as ILoginResult;
    await this.authResult(result, true);
  }

  public logout(history: History<History.UnknownFacade>): void {
    history.push("/");
    this.me = null;
    this.isLoggedIn = false;
    this._api.useLoginResult();
    localStorage.removeItem("session");
  }

  public inGroup(user: IUser, name: string): boolean {
    const groups = [...user.driverGroups, ...user.securityGroups];

    log.breadcrumb("auth", "inGroup", {
      user,
      name,
    });

    for (const group of groups) {
      if (group.name === "Z_App_Preferences") {
        for (const child of group.children) {
          if (child.name === name) {
            return true;
          }
        }
      }
    }

    return false;
  }

  private async authResult(result: ILoginResult, fromSession = false) {
    this._api.useLoginResult(result);

    const userName = result?.credentials?.userName;
    if (!userName) return;

    try {
      const me = await this._drivers.getDriverByUserName(userName);
      localStorage.setItem("session", JSON.stringify(result));

      this.me = me;
      this.isLoggedIn = true;

      LogRocket.identify(me.user.id, {
        name: me.user.firstName + " " + me.user.lastName,
        email: me.user.name,
      });
    } catch (ex) {
      if (fromSession) {
        this._prompt.showErrorAlert(
          i18n.t("login.errors.expired.title"),
          i18n.t("login.errors.expired.subtitle"),
          ex
        );
      }

      this._api.useLoginResult();
      this.isLoggedIn = false;
      this.me = null;
    }
  }
}
