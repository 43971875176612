import { IonButton } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  username: string;
  password: string;
  disabled?: boolean;
  className?: string;
}

export default function LoginButton({
  disabled,
  username,
  password,
  className,
}: IProps): JSX.Element {
  const [t] = useTranslation();
  return (
    <IonButton
      type="submit"
      size="large"
      expand="block"
      disabled={disabled || !username?.length || !password?.length}
      className={className}
    >
      {t("login.login")}
    </IonButton>
  );
}
