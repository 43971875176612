import config from "../../config";
import { Api } from "../api";
import { AuthStore } from "../auth_store";
import { Container } from "../helpers/container";
import IGroup from "../rpc/models/group";
import IUser from "../rpc/models/user";
import { first } from "../utils/array";
import Duration from "../utils/duration";
import ICompanyConfig from "./models/company_config";
import ISecurityGroup from "./models/security_group";

export default class GroupsService {
  constructor(
    container: Container,
    private readonly _api = container.get(Api),
    private readonly _auth = container.get(AuthStore)
  ) {}

  async hasClearance(user: IUser, clearance: string): Promise<boolean> {
    const groups = await this.getSecurityGroups();
    return this.getClearancesForUser(groups, user).has(clearance);
  }

  /**
   * Gets security clearance names for user.
   * @param user - The user to check.
   */
  getClearancesForUser(
    groups: Map<string, ISecurityGroup>,
    user: IUser
  ): Set<string> {
    const results = new Set<string>();
    for (const group of user.securityGroups) {
      this.getClearancesForGroup(groups, group, results);
    }

    return results ?? new Set<string>();
  }

  /**
   * Gets security clearance names for group.
   * @param group - The group to search.
   */
  getClearancesForGroup(
    groups: Map<string, ISecurityGroup>,
    group: IGroup,
    results = new Set<string>()
  ): Set<string> {
    const tree: ISecurityGroup[] = [];

    let depth = 0;
    let current = groups.get(group.id);

    while (current && depth < 100) {
      tree.unshift(current);
      current = current.parent;
      depth++;
    }

    for (const current of tree) {
      for (const filter of current.securityFilters) {
        if (filter.isAdd) {
          results.add(filter.securityIdentifier);
        } else {
          results.delete(filter.securityIdentifier);
        }
      }
    }

    return results;
  }

  /**
   * Get security groups from GeoTab API.
   */
  async getSecurityGroups(): Promise<Map<string, ISecurityGroup>> {
    const all = (await this._api.find("Group", {
      id: "GroupSecurityId",
    })) as ISecurityGroup[];

    const map = new Map<string, ISecurityGroup>();

    for (const group of all) {
      map.set(group.id, group);
    }

    for (const group of all) {
      for (let i = 0; i < group.children.length; i++) {
        const child = map.get(group.children[i].id);
        if (child) {
          child.parent = group;
          group.children[i] = child;

          map.set(child.id, child);
        }
      }

      map.set(group.id, group);
    }

    return map;
  }

  async getCompanyConfig(): Promise<ICompanyConfig> {
    const groups = await this._api.find("Group", { name: "eco-app" });
    const group = first(groups);
    if (!group) throw new Error(`Group ${config.group} not defined in geotab`);
    const comments = group.comments;

    const scoreLimits: [number, number][] = [];
    let scoreInterval = 600_000;
    let summaryTimeSpan = new Duration(0);

    for (const line of comments.split("\n")) {
      {
        // Parse score limits
        const match = line.match(/(\d+)\s*,\s*Grafik\s*(\d+)/);
        if (match) {
          const [, weightRaw, scoreRaw] = match;

          const score = parseInt(scoreRaw, 10);
          const weight = parseInt(weightRaw, 10);

          if (isNaN(score)) continue;
          if (isNaN(weight)) continue;

          scoreLimits.push([weight, score]);

          continue;
        }
      }

      {
        // Parse score interval
        const match = line.match(/(\d+)\s*,\s*Seconds/);
        if (match) {
          const [, secondsRaw] = match;

          scoreInterval = parseInt(secondsRaw, 10);
          if (isNaN(scoreInterval)) scoreInterval = 0;
          continue;
        }
      }

      {
        // Parse summary time span
        const match = line.match(/(\d+)\s*,\s*Hours/);
        if (match) {
          const [, hoursRaw] = match;
          const hours = parseFloat(hoursRaw);

          if (!isNaN(hours)) {
            summaryTimeSpan = Duration.fromHours(hours);
          }

          continue;
        }
      }
    }

    scoreLimits.sort((a, b) => a[0] - b[0]);

    return {
      scoreLimits,
      scoreInterval,
      summaryTimeSpan,
    };
  }
}
