import React from "react";
import NfcButton from "./nfc_button";

export default function VehiclesSearchButtons(): JSX.Element {
  return (
    <div>
      <NfcButton />
    </div>
  );
}
