import { observable } from "mobx";
import { first } from "./utils/array";
import { log } from "./utils/log";
import { Container } from "./helpers/container";
import RpcClient from "./rpc/client";
import { IRpcEndPoint } from "./rpc/endpoint";
import { EntityNameMap, SearchEntityNameMap } from "./rpc/models/entity_map";
import ILoginResult from "./rpc/models/login_result";
import IRpcRequest from "./rpc/request";
import { GetResults } from "./rpc/request_dispatcher";
import { RpcRequestFactory } from "./rpc/request_factory";

export class Api {
  @observable public endPoint: IRpcEndPoint;

  constructor(container: Container, private readonly _client: RpcClient = new RpcClient()) {
    this.endPoint = { url: "https://my.geotab.com/apiv1" };
  }

  public authUser = (database: string, userName: string, password: string) =>
    this._client.authUser(this.endPoint, database, userName, password);

  public authSession = (database: string, sessionId: string) =>
    this._client.authSession(this.endPoint, database, sessionId);

  public batch = <
    TRpcRequests extends ReadonlyArray<IRpcRequest>,
    TRpcRespones = GetResults<TRpcRequests>
  >(
    make: (factory: RpcRequestFactory) => TRpcRequests
  ): Promise<TRpcRespones> => this._client.batch(this.endPoint, make);

  public get = <TType extends keyof EntityNameMap>(type: TType, limit?: number) =>
    this._client.get(this.endPoint, type, limit);

  public getFeed = <
    TType extends keyof SearchEntityNameMap,
    TEntity = SearchEntityNameMap[TType]["type"],
    TSearchEntity = SearchEntityNameMap[TType]["search"]
  >(
    typeName: TType,
    search?: TSearchEntity,
    fromVersion?: string,
    resultsLimit?: number
  ) => this._client.getFeed(this.endPoint, typeName, search, fromVersion, resultsLimit);

  public find = <
    TType extends keyof EntityNameMap & keyof SearchEntityNameMap,
    TSearchEntity extends SearchEntityNameMap[TType]["search"]
  >(
    type: TType,
    search: Partial<TSearchEntity>,
    limit?: number
  ) => this._client.find(this.endPoint, type, search, limit);

  public async findOne<
    TType extends keyof EntityNameMap & keyof SearchEntityNameMap,
    TSearchEntity extends SearchEntityNameMap[TType]["search"]
  >(type: TType, search: Partial<TSearchEntity>, limit?: number) {
    const result = await this._client.find(this.endPoint, type, search, limit);
    return first(result);
  }

  public async findOneOrFail<
    TType extends keyof EntityNameMap & keyof SearchEntityNameMap,
    TSearchEntity extends SearchEntityNameMap[TType]["search"]
  >(type: TType, search: Partial<TSearchEntity>, limit?: number) {
    const results = await this._client.find(this.endPoint, type, search, limit);
    const result = first(results);
    if (!result) throw new Error("Find didn't return a result.");

    return result;
  }

  public create = <TType extends keyof EntityNameMap, TEntity extends EntityNameMap[TType]>(
    type: TType,
    entity: Partial<Omit<TEntity, "id">>
  ) => this._client.create(this.endPoint, type, entity);

  public update = <TType extends keyof EntityNameMap, TEntity extends EntityNameMap[TType]>(
    type: TType,
    entity: Partial<TEntity>
  ) => this._client.update(this.endPoint, type, entity);

  public delete = <TType extends keyof EntityNameMap, TEntity extends EntityNameMap[TType]>(
    type: TType,
    entity: Partial<TEntity>
  ) => this._client.delete(this.endPoint, type, entity);

  public useLoginResult(result?: ILoginResult) {
    log.breadcrumb("api", "useLoginResult", { path: result?.path });

    if (!result || !result.path || !result.credentials) {
      this.endPoint = { url: "https://my.geotab.com/apiv1" };
    } else {
      if (result.path !== "ThisServer") {
        this.endPoint.url = "https://" + result.path + "/apiv1";
      }

      this.endPoint.credentials = result.credentials;
    }
  }
}
