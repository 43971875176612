import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../core/vehicles/vehicle";
import VehicleItemSubtitle from "./item_subtitle";

interface IProps {
  vehicle: Vehicle;
}

export default function VehicleItemPlate(props: IProps): JSX.Element {
  const [t] = useTranslation();

  return useObserver(() => {
    const licensePlate = props.vehicle.device.licensePlate ?? t("unknown");

    return (
      <p>
        <VehicleItemSubtitle
          title={t("vehicles.licensePlate")}
          caption={licensePlate}
        />
      </p>
    );
  });
}
