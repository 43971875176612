import {
  busOutline,
  gridOutline,
  logInOutline,
  readerOutline,
} from "ionicons/icons";
import LoginPage from "../../components/login/page_login";
import OpenDashboardPage from "../../components/navigation/main/dashboard";
import TripPage from "../../components/trip/page_trip";
import TripSummaryPage from "../../components/trip/page_trip_summary";
import VehiclesPage from "../../components/vehicles/page_vehicles";
import i18n from "../../i18n";
import TripState from "../trip/trip_state";
import IRoute from "./route";

const routes: IRoute[] = [
  {
    link: "/login",
    icon: logInOutline,
    exact: true,
    title: i18n.t("main.login.title"),
    subtitle: i18n.t("main.login.subtitle"),
    component: (_) => LoginPage,
    canNavigate: (app) => !app.auth.isLoggedIn,
  },

  // Vehicles
  {
    link: "/vehicles",
    icon: busOutline,
    title: i18n.t("main.vehicles.title"),
    subtitle: i18n.t("main.vehicles.subtitle"),
    component: (_) => VehiclesPage,
    canNavigate: (app) => {
      if (!app.auth.isLoggedIn) return false;
      if (app.status.state === TripState.None) return true;
      if (app.status.state === TripState.Complete) return true;

      return false;
    },
  },

  // Trip
  {
    link: "/trip",
    icon: busOutline,
    title: i18n.t("main.trip.title"),
    subtitle: i18n.t("main.trip.subtitle"),
    component: (_) => TripPage,
    canNavigate: (app) => {
      if (!app.auth.isLoggedIn) return false;
      if (app.status.state === TripState.None) return false;
      if (app.status.state === TripState.Complete) return false;

      return true;
    },
  },

  // Trip Summary
  {
    link: "/trip_summary",
    icon: readerOutline,
    title: i18n.t("main.trip_summary.title"),
    subtitle: i18n.t("main.trip_summary.subtitle"),
    component: (_) => TripSummaryPage,
    canNavigate: (app) => {
      if (!app.auth.isLoggedIn) return false;
      // if (app.status.state === TripState.None) return false;

      return true;
    },
  },

  // Dashboard
  {
    icon: gridOutline,
    title: i18n.t("main.dashboard.title"),
    subtitle: i18n.t("main.dashboard.subtitle"),

    onClick: (app) => OpenDashboardPage(app.api),
    canNavigate: (app) => app.auth.isLoggedIn,
  },
];

export default routes;
