import { Plugins } from "@capacitor/core";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from "./locales/de.json";
import en from "./locales/en.json";
const { Device } = Plugins;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      de: { translation: de },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.changeLanguage(navigator.language);
window.addEventListener("languagechange", () => {
  i18n.changeLanguage(navigator.language);
});

export default i18n;
