const SECONDS_MS = 1000;
const MINUTES_MS = SECONDS_MS * 60;
const HOURS_MS = MINUTES_MS * 60;
const DAYS_MS = HOURS_MS * 24;
const WEEKS_MS = DAYS_MS * 7;
const MONTHS_MS = WEEKS_MS * 4;
const YEARS_MS = MONTHS_MS * 12;
const INTERVALS: [number, string][] = [
  [YEARS_MS, "Y"],
  [MONTHS_MS, "M"],
  [WEEKS_MS, "W"],
  [DAYS_MS, "day"],
  [HOURS_MS, "h"],
  [MINUTES_MS, "m"],
  [SECONDS_MS, "s"],
];

export default class Duration {
  public get totalHours(): number {
    return this.totalMilliseconds / HOURS_MS;
  }

  public get totalMinutes(): number {
    return this.totalMilliseconds / MINUTES_MS;
  }

  public get totalSeconds(): number {
    return this.totalMilliseconds / SECONDS_MS;
  }

  constructor(public totalMilliseconds: number) {}

  public toLocaleString(): string {
    let left = this.totalMilliseconds;
    let results = [];

    for (const [interval, suffix] of INTERVALS) {
      if (left >= interval) {
        const count =
          left === 0
            ? 0 //
            : Math.floor(left / interval);

        if (count === 0 && results.length > 0) {
          break;
        }

        left -= count * interval;
        results.push(count + suffix);
      }
    }

    if (results.length === 0) {
      return Math.round(this.totalSeconds) + "s";
    }

    return results.join(" ");
  }

  public static parse(value: string): Duration {
    const expr = /(\d+):(\d+):(\d+(?:\.\d+)?)/;
    const match = value.match(expr);
    if (!match) {
      throw new Error(`Failed to parse duration '${value}'`);
    }

    const [hours, minutes, seconds] = [
      parseFloat(match[1]),
      parseFloat(match[2]),
      parseFloat(match[3]),
    ];

    return new Duration(
      hours * HOURS_MS + minutes * MINUTES_MS + seconds * SECONDS_MS
    );
  }

  static fromHours(hours: number): Duration {
    return new Duration(hours * 60 * 60 * 1000);
  }
}
