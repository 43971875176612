import { computed, observable } from "mobx";
import i18n from "../../i18n";
import { log } from "../utils/log";
import { AuthStore } from "../auth_store";
import { Driver } from "../drivers/driver";
import { Container } from "../helpers/container";
import { PromptStore } from "../prompt_store";
import { Vehicle } from "../vehicles/vehicle";
import { VehiclesService } from "../vehicles/vehicles_service";
import DriverChangeService from "./driver_change_service";
import TripState from "./trip_state";
import { Api } from "../api";

const UPDATE_INTERVAL = 10_000;

export default class StatusStore {
  @computed
  public get state(): TripState {
    return this._state;
  }

  @computed
  public get driver(): Driver | null {
    return this._driver ?? null;
  }

  @computed
  public get vehicle(): Vehicle | null {
    return this._vehicle ?? null;
  }

  constructor(
    container: Container,
    private readonly _api = container.get(Api),
    private readonly _auth = container.get(AuthStore),
    private readonly _prompt = container.get(PromptStore),
    private readonly _service = container.get(DriverChangeService),
    private readonly _vehicles = container.get(VehiclesService)
  ) {
    setTimeout(() => this.update(), 1_000);
    setInterval(() => this.update(), UPDATE_INTERVAL);
  }

  public async assignTo(
    vehicle: Vehicle,
    checkIfIgnitionOnAndDriverAssigned = false
  ): Promise<void> {
    try {
      const confirmed = await this.confirm(
        vehicle,
        checkIfIgnitionOnAndDriverAssigned
      );
      if (!confirmed) return;

      const state = await this._service.setMyVehicle(vehicle);
      this.updateState(state);
      await this.updateVehicle(vehicle);
    } catch (ex) {
      this._prompt.showErrorAlert(
        i18n.t("unexpected_error"),
        i18n.t("trip.assign.assign_failed"),
        ex
      );
    }
  }

  public async stop(hardStop = false): Promise<void> {
    try {
      this.updateState(hardStop ? TripState.None : TripState.Complete);
      this.updateVehicle(null);

      await this._service.resetMyVehicle();
    } catch (ex) {
      this._prompt.showErrorAlert(
        i18n.t("unexpected_error"),
        i18n.t("trip.errors.unassign.subtitle"),
        ex
      );
    }
  }

  public continue(): Promise<void> {
    if (!this._lastVehicle || this._state !== TripState.Complete) {
      return Promise.resolve();
    }

    return this.assignTo(this._lastVehicle, true);
  }

  private async update(): Promise<void> {
    try {
      const [state, deviceId] = await this._service.getStateAndDeviceId();
      if (state == this._state && this._vehicle?.device.id === deviceId) {
        return;
      }

      if (state === TripState.None && this._wasInTrip) {
        this.updateState(TripState.Complete);
      } else if (state === TripState.Active && this._wasInTrip) {
        const trips = await this._api.find("Trip", {
          fromDate: new Date(Date.now() - 1_000 * 60 * 60 * 24).toISOString(),
          driverSearch: this._auth.me?.user,
          deviceSearch: { id: deviceId },
        });

        let estimatedState = TripState.Complete;

        for (const trip of trips) {
          const stopDate = new Date(trip.stop);
          if (stopDate > new Date()) {
            estimatedState = TripState.Active;
            break;
          }
        }

        this.updateState(estimatedState);
      } else {
        this.updateState(state);
      }

      await this.updateVehicleWithDeviceId(deviceId);
    } catch (ex) {
      log.error(ex);
    }
  }

  private updateState(state: TripState): void {
    this._state = state;

    if (state == TripState.Active) {
      this._wasInTrip = true;
    }
  }

  private async updateVehicle(vehicle: Vehicle | null): Promise<void> {
    if (this._vehicle?.device.id === vehicle?.device.id) return;
    if (!vehicle) {
      this._vehicle = undefined;
      return;
    }

    this._driver = (await vehicle.getDriver()) ?? undefined;
    this._vehicle = vehicle;
    this._lastVehicle = vehicle;
  }

  private async updateVehicleWithDeviceId(
    deviceId: string | null
  ): Promise<void> {
    if (this._vehicle?.device.id === deviceId) return;
    if (!deviceId) {
      this._vehicle = undefined;
      return;
    }

    await this.updateVehicle(await this._vehicles.getVehicle(deviceId));
  }

  private async confirm(
    vehicle: Vehicle,
    checkIfIgnitionOnAndDriverAssigned: boolean
  ): Promise<boolean> {
    try {
      const me = this._auth.me?.user;
      const driver = await this._service.getDriverForDevice(vehicle.device);
      const shouldConfirm = checkIfIgnitionOnAndDriverAssigned
        ? (await vehicle.getIsIgnitionOn()) &&
          driver &&
          driver.user.id !== me?.id
        : driver && driver.user.id !== me?.id;

      if (shouldConfirm) {
        return await this._prompt.showConfirm(
          i18n.t("trip.assign.alert.title"),
          i18n.t("trip.assign.alert.subtitle.reassign", {
            driverName: driver?.user.name,
            vehicleName: vehicle.device.name,
          })
        );
      }

      if (checkIfIgnitionOnAndDriverAssigned) {
        return true;
      }
    } catch (ex) {}

    try {
      return await this._prompt.showConfirm(
        i18n.t("trip.assign.alert.title"),
        i18n.t("trip.assign.alert.subtitle.assign", {
          vehicleName: vehicle.device.name,
        })
      );
    } catch (ex) {}

    return false;
  }

  @observable
  private _state = TripState.None;
  @observable
  private _driver?: Driver;
  @observable
  private _vehicle?: Vehicle;
  private _wasInTrip = false;
  private _lastVehicle?: Vehicle;
}
