import { IonSearchbar } from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../hooks/app_store";

export default function VehiclesSearch(): JSX.Element {
  const [t] = useTranslation();
  const { vehicles } = useApp();
  return useObserver(() => (
    <IonSearchbar
      value={vehicles.search}
      placeholder={t("vehicles.search")}
      onIonChange={(evt) => (vehicles.search = evt.detail.value ?? "")}
    />
  ));
}
