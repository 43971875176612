import { first } from "../utils/array";
import { log } from "../utils/log";
import { Api } from "../api";
import { AuthStore } from "../auth_store";
import { Driver } from "../drivers/driver";
import { DriversStore } from "../drivers/drivers_store";
import { Container } from "../helpers/container";
import IDevice from "../rpc/models/device";
import IDriverChange from "../rpc/models/driver_change";
import IStatusData from "../rpc/models/status_data";
import IUser from "../rpc/models/user";
import { Vehicle } from "../vehicles/vehicle";
import TripState from "./trip_state";

const PENDING_CHANGE_KEY = "driverChange";
const IGNTIION_EVENT_LOOKBACK_TIME = 1000 * 60 * 60 * 24;

interface IPending {
  dateTime: Date;
  deviceId: string;
}

export default class DriverChangeService {
  constructor(
    container: Container,
    private readonly _api = container.get(Api),
    private readonly _auth = container.get(AuthStore),
    private readonly _drivers = container.get(DriversStore)
  ) {}

  public async getStateAndDeviceId(): Promise<[TripState, string | null]> {
    log.breadcrumb("DriverChangeService", "getStateAndDeviceId.begin");

    const me = this._auth.me?.user;
    if (!me) return [TripState.None, null];

    const pending = this.getPending();

    log.breadcrumb(
      "DriverChangeService",
      "getStateAndDeviceId.getPending",
      pending
    );

    if (pending) {
      if (await this.tryAssignPending(pending)) {
        return [TripState.Active, pending.deviceId];
      }

      return [TripState.Pending, pending.deviceId];
    }

    log.breadcrumb(
      "DriverChangeService",
      "getStateAndDeviceId.getDeviceIDForUser",
      pending
    );

    const deviceId = await this.getDeviceIdForUser(me);
    if (!deviceId) {
      return [TripState.None, null];
    }

    return [TripState.Active, deviceId];
  }

  public async setMyVehicle(vehicle: Vehicle): Promise<TripState> {
    log.breadcrumb("DriverChangeService", "setMyVehicle.begin", vehicle);

    const me = this._auth.me?.user;
    if (!me) return TripState.None;

    log.breadcrumb(
      "DriverChangeService",
      "setMyVehicle.getDriverIdForDevice",
      vehicle
    );

    const driverId = await this.getDriverIdForDevice(vehicle.device);
    if (driverId && driverId !== me.id && await vehicle.getIsIgnitionOn()) {
      this.setPending(vehicle);
      return TripState.Pending;
    }

    log.breadcrumb(
      "DriverChangeService",
      "setMyVehicle.createDriverChange",
      vehicle
    );

    await this._api.create("DriverChange", {
      type: "Driver",
      device: { id: vehicle.device.id },
      driver: { id: me.id },
      dateTime: new Date().toISOString(),
    });

    return TripState.Active;
  }

  public async resetMyVehicle(): Promise<void> {
    this.setPending();

    const me = this._auth.me?.user;
    if (!me) return;

    const deviceId = await this.getDeviceIdForUser(me);
    if (!deviceId) {
      return;
    }

    await this._api.create("DriverChange", {
      type: "Driver",
      device: { id: deviceId },
      driver: "UnknownDriverId",
      dateTime: new Date().toISOString(),
    });
  }

  public async getDriverForDevice(device: IDevice): Promise<Driver | null> {
    const driverId = await this.getDriverIdForDevice(device);
    if (!driverId) return null;

    return this._drivers.getDriver(driverId);
  }

  private async getDeviceIdForUser(user?: IUser): Promise<string | null> {
    if (!user) return null;

    const myChange = first(
      await this._api.find("DriverChange", {
        userSearch: { id: user.id },
        includeOverlappedChanges: true,
      })
    );

    if (!myChange) return null;
    if (myChange.driver.id !== user.id) return null;

    const driver = await this.getDriverIdForDevice(myChange.device);
    if (!driver) return null;
    if (driver !== user.id) return null;

    return myChange.device.id;
  }

  private async getDriverIdForDevice(device: {
    id: string;
  }): Promise<string | null> {
    const deviceChange = first(
      await this._api.find("DriverChange", {
        deviceSearch: { id: device.id },
        includeOverlappedChanges: true,
      })
    );

    if (!deviceChange) return null;
    return deviceChange.driver.id;
  }

  private setPending(vehicle?: Vehicle): void {
    if (!vehicle) {
      localStorage.removeItem(PENDING_CHANGE_KEY);
      return;
    }

    localStorage.setItem(
      PENDING_CHANGE_KEY,
      JSON.stringify({
        dateTime: Date.now(),
        deviceId: vehicle?.device.id,
      })
    );
  }

  private getPending(): IPending | null {
    const json = localStorage.getItem(PENDING_CHANGE_KEY);
    if (!json) return null;
    const data = JSON.parse(json);

    if (typeof data.dateTime !== "number") return null;
    if (typeof data.deviceId !== "string") return null;

    return {
      dateTime: new Date(data.dateTime),
      deviceId: data.deviceId,
    };
  }

  private async tryAssignPending(pending: IPending): Promise<boolean> {
    log.breadcrumb("DriverChangeService", "tryAssignPending.begin");

    const me = this._auth.me?.user;
    if (!me) return false;

    const [change, events] = await this.getChangesAndIgntions(
      pending.deviceId,
      pending.dateTime
    );
    const isUnassigned = this.isUnassigned(change);
    const isIgnitionOff = this.isIgnitionOff(events);

    log.breadcrumb(
      "DriverChangeService",
      "getStateAndDeviceId.getUnassignDataForDeviceId",
      { isUnassigned, isIgnitionOff }
    );

    // Check if igntion was turned on
    if (isUnassigned || isIgnitionOff) {
      try {
        log.info("DriverChangeService.applyPendingDriverChange", {
          me,
          pending,
          isUnassigned,
          isIgnitionOff,
        });

        await this._api.create("DriverChange", {
          type: "Driver",
          device: { id: pending.deviceId },
          driver: { id: me.id },
          dateTime: new Date().toISOString(),
        });

        // We assigned, clear pending data
        this.setPending();

        return true;
      } catch (ex) {
        log.error(ex);
      }
    }

    return false;
  }

  private isIgnitionOff(events: IStatusData[]): boolean {
    if (events.length === 0) return true;

    for (const event of events) {
      if (event.data === 0) {
        return true;
      }
    }

    return false;
  }

  private isUnassigned(change?: IDriverChange): boolean {
    if (!change) return true;
    if (typeof change.driver === "string") return true;

    return false;
  }

  private getUnassignData(
    changes: IDriverChange[],
    events: IStatusData[]
  ): IDriverChange | IStatusData | null {
    const length = Math.max(changes.length, events.length);
    for (let i = 0; i < length; i++) {
      const change = i > changes.length ? undefined : changes[i];
      const status = i > events.length ? undefined : events[i];

      if (typeof change?.driver === "string") return change;
      if (status?.data === 0) return status;
    }

    return null;
  }

  private async getChangesAndIgntions(
    deviceId: string,
    fromDate: Date
  ): Promise<[IDriverChange | undefined, IStatusData[]]> {
    type Result = [IDriverChange[], IStatusData[]];
    const result: Result = await this._api.batch((factory) => [
      factory.find("DriverChange", {
        fromDate: fromDate.toISOString(),
        deviceSearch: { id: deviceId },
        includeOverlappedChanges: true,
      }),
      factory.find("StatusData", {
        fromDate: new Date(
          Date.now() - IGNTIION_EVENT_LOOKBACK_TIME
        ).toISOString(),
        deviceSearch: { id: deviceId },
        diagnosticSearch: { id: "DiagnosticIgnitionId" },
      }),
    ]);

    return [result[0][0], result[1]];
  }
}
