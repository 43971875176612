import "isomorphic-fetch";
import ICredentials from "./models/credentials";
import { IRpcEndPoint } from "./endpoint";
import { RpcError } from "./error";
import IRpcRequest from "./request";
import IRpcResponse from "./response";
import { log } from "../utils/log";

export type GetResult<T> = T extends IRpcRequest<any, infer TResponse>
  ? TResponse
  : never;

export type GetResults<T> = {
  [K in keyof T]: T[K] extends IRpcRequest<any, infer V> ? V : never;
};

export interface IRpcRequestDispatcher {
  dispatch<TRequest extends IRpcRequest, TResult = GetResult<TRequest>>(
    to: IRpcEndPoint,
    req: TRequest
  ): Promise<TResult>;

  dispatchBatched<
    TRequests extends ReadonlyArray<IRpcRequest>,
    TResults = GetResults<TRequests>
  >(
    to: IRpcEndPoint,
    reqs: TRequests
  ): Promise<TResults>;
}

export class RpcRequestDispatcher implements IRpcRequestDispatcher {
  public async dispatch<
    TRequest extends IRpcRequest,
    TResult = GetResult<TRequest>
  >(to: IRpcEndPoint, req: TRequest): Promise<TResult> {
    if (to.credentials) {
      req.params.credentials = to.credentials;
    }

    this.logRequest(req);
    return fetch(to.url, {
      body: JSON.stringify(req),
      method: "POST",
    })
      .then((res) => res.json())
      .then((res: IRpcResponse<TResult>) => {
        log.breadcrumb("api", "res", res);

        if (res.error) throw RpcError.fromResponseError(res.error);
        if (!res.result) throw new Error("RpcResponse null result");

        return res.result;
      });
  }

  public dispatchBatched<
    TRequests extends ReadonlyArray<IRpcRequest>,
    TResults = GetResults<TRequests>
  >(to: IRpcEndPoint, calls: TRequests): Promise<TResults> {
    this.logRequests(calls);

    if (calls.length <= 0) {
      return Promise.reject(new Error("Empty batch request"));
    }

    return fetch(to.url, {
      body: JSON.stringify({
        method: "ExecuteMultiCall",
        params: { calls, credentials: to.credentials },
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((res: IRpcResponse<TResults>) => {
        log.breadcrumb("api", "res", res);

        if (res.error) throw RpcError.fromResponseError(res.error);
        if (!res.result) throw new Error("RpcResponse null result");

        return res.result;
      });
  }

  private logRequests = <TRequests extends ReadonlyArray<IRpcRequest>>(
    reqs: TRequests
  ): void => reqs.forEach((req) => this.logRequest(req));

  private logRequest(req: IRpcRequest) {
    const { credentials, password, sessionId, ...data } = req.params;

    log.breadcrumb("api", "req", data);
  }
}
