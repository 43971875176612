import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { exitOutline } from "ionicons/icons";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useApp } from "../../hooks/app_store";
import { useHistory } from "react-router";

interface IProps {
  onClick: () => void;
}

export default function LogoutItem({ onClick }: IProps): JSX.Element {
  const [t] = useTranslation();
  const { auth } = useApp();
  const history = useHistory();

  return useObserver(() => {
    if (!auth.isLoggedIn) return <></>;
    return (
      <IonItem
        color="danger"
        button
        onClick={() => {
          auth.logout(history);
          onClick();
        }}
      >
        <IonLabel>
          <h2>{t("settings.logout.title")}</h2>
          <p>{t("settings.logout.subtitle")}</p>
        </IonLabel>
        <IonIcon icon={exitOutline} />
      </IonItem>
    );
  });
}
