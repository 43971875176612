import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import openExternal from "../../core/utils/external";

interface IProps {
  onChanged: (value: boolean) => void;
}

export default function EULAInput(props: IProps): JSX.Element {
  const [isAgreed, setAgreed] = useState(false);

  useEffect(() => {
    const isAgreed = !!localStorage.getItem("eula");
    const wasLoggedIn = !!localStorage.getItem("wasLoggedIn");

    setAgreed(isAgreed && wasLoggedIn);
    props.onChanged(isAgreed);
  });

  if (isAgreed) {
    return <></>;
  }

  return (
    <IonItem>
      <IonLabel>
        <EULALink />
      </IonLabel>
      <IonCheckbox
        onIonChange={(evt) => {
          if (evt.detail.checked) {
            localStorage.setItem("eula", "true");
          } else {
            localStorage.removeItem("eula");
          }

          props.onChanged(evt.detail.checked);
        }}
      />
    </IonItem>
  );
}

function EULALink(): JSX.Element {
  const [t] = useTranslation();
  return (
    <a onClick={() => openExternal("http://eco.fairfleet.de/impressum")}>
      {t("legal.agree_to_terms")}
    </a>
  );
}
