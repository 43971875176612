import React, { createContext, useContext } from "react";
import { observable } from "mobx";
import { App } from "../core/app_store";
import createPlugin from "logrocket-mobx";
const LogRocket = require("logrocket");

const lr = createPlugin(LogRocket);

const Context = createContext<App>(null as never);

export const useApp = (): App => {
  const res = useContext(Context);
  return res;
};

export function AppProvider(props: { children: JSX.Element }): JSX.Element {
  const value = new App();

  lr.watchObject(value);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}
