import { IonListHeader } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import licensesAll from "./license.json";
import LicenseItem from "./license_item";

export default function LicenseList(): JSX.Element {
  const [t] = useTranslation();
  const licenses = licensesAll as { [index: string]: any };

  return (
    <>
      <IonListHeader>{t("about.licenses")}</IonListHeader>
      {
        Object
          .keys(licenses)
          .map(name => <LicenseItem 
            key={name}
            name={name}
            href={licenses[name].repository}
            kind={licenses[name].licenses}
          />)
      }
    </>
  );
}
