import "@capacitor-community/http";
import React from "react";
import img1 from "../../../assets/faces/1.svg";
import img2 from "../../../assets/faces/2.svg";
import img3 from "../../../assets/faces/3.svg";
import img4 from "../../../assets/faces/4.svg";
import img5 from "../../../assets/faces/5.svg";
import { ReactComponent as ImgOffline } from "../../../assets/faces/offline.svg";

interface IProps {
  score: number;
  isOffline: boolean;
}

export default function Score(props: IProps): JSX.Element {
  const image =
    props.score === 1
      ? img1
      : props.score === 2
      ? img2
      : props.score === 3
      ? img3
      : props.score === 4
      ? img4
      : img5;

  if (props.isOffline) {
    return <ImgOffline />;
  }

  return <img src={image} width="100%" height="100%" />;
}
