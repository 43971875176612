import { EntityNameMap, SearchEntityNameMap } from "./models/entity_map";
import { IRpcEndPoint } from "./endpoint";
import IRpcRequest from "./request";
import {
  GetResults,
  IRpcRequestDispatcher,
  RpcRequestDispatcher,
} from "./request_dispatcher";
import { RpcRequestFactory } from "./request_factory";

export default class RpcClient {
  constructor(
    private readonly _dispatcher: IRpcRequestDispatcher = new RpcRequestDispatcher()
  ) {}

  public batch<
    TRpcRequests extends ReadonlyArray<IRpcRequest>,
    TRpcRespones = GetResults<TRpcRequests>
  >(
    endPoint: IRpcEndPoint,
    make: (factory: RpcRequestFactory) => TRpcRequests
  ): Promise<TRpcRespones> {
    return this._dispatcher.dispatchBatched(
      endPoint,
      make(new RpcRequestFactory())
    );
  }

  public authUser(
    endPoint: IRpcEndPoint,
    database: string,
    userName: string,
    password: string
  ) {
    return this._dispatcher.dispatch(
      endPoint,
      new RpcRequestFactory().auth({
        database,
        userName,
        password,
      })
    );
  }

  public authSession(
    endPoint: IRpcEndPoint,
    database: string,
    sessionId: string
  ) {
    return this._dispatcher.dispatch(
      endPoint,
      new RpcRequestFactory().auth({
        database,
        sessionId,
      })
    );
  }

  public get<TType extends keyof EntityNameMap>(
    endPoint: IRpcEndPoint,
    type: TType,
    limit?: number
  ) {
    return this._dispatcher.dispatch(
      endPoint,
      new RpcRequestFactory().get(type, limit)
    );
  }

  public getFeed<
    TType extends keyof SearchEntityNameMap,
    TEntity = SearchEntityNameMap[TType]["type"],
    TSearchEntity = SearchEntityNameMap[TType]["search"]
  >(
    endPoint: IRpcEndPoint,
    typeName: TType,
    search?: TSearchEntity,
    fromVersion?: string,
    resultsLimit?: number
  ) {
    return this._dispatcher.dispatch(
      endPoint,
      new RpcRequestFactory().getFeed(
        typeName,
        search,
        fromVersion,
        resultsLimit
      )
    );
  }

  public find<
    TType extends keyof EntityNameMap & keyof SearchEntityNameMap,
    TSearchEntity extends SearchEntityNameMap[TType]["search"]
  >(
    endPoint: IRpcEndPoint,
    type: TType,
    search: Partial<TSearchEntity>,
    limit?: number
  ) {
    return this._dispatcher.dispatch(
      endPoint,
      new RpcRequestFactory().find(type, search, limit)
    );
  }

  public create<
    TType extends keyof EntityNameMap,
    TEntity extends EntityNameMap[TType]
  >(endPoint: IRpcEndPoint, type: TType, entity: Partial<Omit<TEntity, "id">>) {
    return this._dispatcher.dispatch(
      endPoint,
      new RpcRequestFactory().create(type, entity)
    );
  }

  public async update<
    TType extends keyof EntityNameMap,
    TEntity extends EntityNameMap[TType]
  >(endPoint: IRpcEndPoint, type: TType, entity: Partial<TEntity>) {
    return this._dispatcher.dispatch(
      endPoint,
      new RpcRequestFactory().update(type, entity)
    );
  }

  public async delete<
    TType extends keyof EntityNameMap,
    TEntity extends EntityNameMap[TType]
  >(endPoint: IRpcEndPoint, type: TType, entity: Partial<TEntity>) {
    return this._dispatcher.dispatch(
      endPoint,
      new RpcRequestFactory().delete(type, entity)
    );
  }
}
