import { IonAlert } from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useApp } from "../../hooks/app_store";

interface IDatabaseAlertProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function DatabaseAlert(props: IDatabaseAlertProps): JSX.Element {
  const [t] = useTranslation();
  const { settings } = useApp();

  return useObserver(() => (
    <IonAlert
      isOpen={props.isOpen}
      onDidDismiss={() => props.onClose()}
      header={t("settings.database.title")}
      message={t("settings.database.subtitle")}
      inputs={[
        {
          type: "text",
          name: "databaseName",
          value: settings.database,
        },
      ]}
      buttons={[
        {
          text: t("cancel"),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: t("ok"),
          handler: (inputs) => settings.setDatabase(inputs.databaseName),
        },
      ]}
    />
  ));
}
