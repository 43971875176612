import React from "react";
import { useTranslation } from "react-i18next";
import { log } from "../core/utils/log";
import { useApp } from "../hooks/app_store";
import "./version_check.scss";

export default function VersionCheck(): JSX.Element {
  const [t] = useTranslation();
  const { useIsAppOutOfDate, useIsWebOutOfDate } = useApp();
  const isAppOutdated = useIsAppOutOfDate();
  const isWebOutdated = useIsWebOutOfDate();

  if (isWebOutdated) {
    log.debug("Version out of date. Reloading");
    window.location.reload();
  }

  if (isAppOutdated) {
    return (
      <div className="version-check">
        <h1>{t("version.title")}</h1>
        <p>{t("version.subtitle")}</p>
      </div>
    );
  }

  return <></>;
}
