import { IonTitle, IonToolbar } from "@ionic/react";
import { menuOutline, settingsOutline } from "ionicons/icons";
import { useObserver } from "mobx-react-lite";
import React from "react";
import logo from "../../../assets/logo.svg";
import { Driver } from "../../../core/drivers/driver";
import TripState from "../../../core/trip/trip_state";
import { useApp } from "../../../hooks/app_store";
import { SETTINGS_MENU_ID } from "../../settings/menu";
import { MAIN_MENU_ID } from "../main/menu";
import MenuButton from "./menu_button";

export default function ToolBar(): JSX.Element {
  const { auth, status } = useApp();
  return useObserver(() => (
    <IonToolbar>
      <MenuButton icon={menuOutline} menu={MAIN_MENU_ID} slot="start" />

      <IonTitle>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p>
            {status.state === TripState.Active && status.vehicle
              ? status.vehicle.device.licensePlate
              : "Fairfleet"}
          </p>
          <div style={{ flexGrow: 1 }} />
          <img src={logo} width={30} height={30} style={{ margin: "auto 0" }} />
        </div>
      </IonTitle>

      <MenuButton icon={settingsOutline} slot="end" menu={SETTINGS_MENU_ID} />
    </IonToolbar>
  ));
}

function getUserName(driver: Driver | null): string {
  const user = driver?.user;
  if (!user) return "Fairfleet";
  if (user.firstName?.length ?? 0 > 0) {
    return user.firstName + " " + user.lastName;
  }

  return user.name;
}
