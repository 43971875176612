import { Api } from "../api";
import { getCachedResolver } from "../helpers/cache";
import { Container } from "../helpers/container";
import IUser from "../rpc/models/user";
import { Driver } from "./driver";

export class DriversStore {
  constructor(
    container: Container,
    private readonly _api = container.get(Api)
  ) {}

  public getDriver = getCachedResolver(async (id: string) =>
    this.getDriverFromUser((await this._api.find("User", { id } as never))[0])
  );

  public getDriverByUserName = getCachedResolver(async (userName: string) =>
    this.getDriverFromUser(
      (await this._api.find("User", { name: userName } as never))[0]
    )
  );

  private getDriverFromUser(user: IUser) {
    return new Driver(user, this._api);
  }
}
