import { ObservableMap } from "mobx";

type Cached<TResult> = {
  result: TResult;
  created: number;
};

type PromiseValue<T> = T extends Promise<infer P> ? P : never;

export function getCachedResolver<
  TResolve extends (...args: any[]) => any,
  TArgs extends Parameters<TResolve>,
  TReturn extends ReturnType<TResolve>,
  TResult extends PromiseValue<TReturn>,
>(
  resolve: TResolve,
  maxTimeMs = 1_000,
  cache = new ObservableMap<string, Cached<TReturn>>()
): (...args: TArgs) => Promise<TResult> {
  const getUpdated = async (args: TArgs): Promise<TResult> => {
    const result = await resolve(...args);
    const created = Date.now();

    cache.set(JSON.stringify(args), { result, created });
    return result;
  };

  return async (...args: TArgs): Promise<TResult> => {
    const key = JSON.stringify(args);
    const cached = cache.get(key);
    if (!cached) {
      return await getUpdated(args);
    }

    const deltaMs = Date.now() - cached.created;
    if (deltaMs > maxTimeMs) {
      cache.delete(key);
      return await getUpdated(args);
    }

    return cached.result;
  };
}
