import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
} from "@ionic/react";
import { busOutline, createOutline } from "ionicons/icons";
import { useObserver } from "mobx-react-lite";
import React, { createRef } from "react";
import { Vehicle } from "../../../core/vehicles/vehicle";
import { useApp } from "../../../hooks/app_store";
import VehicleItemHeader from "./item_header";
import VehicleItemDistance from "./item_subtitle_distance";
import VehicleItemPlate from "./item_subtitle_plate";

interface IProps {
  vehicle: Vehicle;
}

export default function VehicleItem(props: IProps): JSX.Element {
  const { nfc, status } = useApp();
  const sliding = createRef<HTMLIonItemSlidingElement>();

  return useObserver(() => (
    <>
      <IonItemSliding ref={sliding}>
        <IonItem button onClick={() => status.assignTo(props.vehicle)}>
          <IonIcon icon={busOutline} slot="start" color="primary" />

          <IonLabel>
            <VehicleItemHeader vehicle={props.vehicle} />
            <VehicleItemDistance vehicle={props.vehicle} />
            <VehicleItemPlate vehicle={props.vehicle} />
          </IonLabel>
        </IonItem>

        <IonItemOptions side="end" className={nfc.canWrite ? "" : "ion-hide"}>
          <IonItemOption
            type="button"
            slot="icon-only"
            color="light"
            onClick={() => {
              sliding.current?.close();
              nfc.writeTag(props.vehicle);
            }}
          >
            <IonIcon slot="icon-only" icon={createOutline} />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </>
  ));
}
