import { computed, observable } from "mobx";
import { Container } from "../helpers/container";

interface ISettings {
  database: string;
}

export class SettingsStore {
  @computed
  public get database(): string {
    return this._settings.database;
  }

  constructor(_: Container) {
    this._settings = this.load();
  }

  public setDatabase(value: string): void {
    this._settings.database = value;
    this.save();
  }

  private load(): ISettings {
    const json = localStorage.getItem("settings") ?? "{}";
    const value = JSON.parse(json);
    return {
      database: value?.database ?? "",
    };
  }

  private save() {
    const value = JSON.stringify(this._settings);
    localStorage.setItem("settings", value);
  }

  @observable
  private _settings = this.load();
}
