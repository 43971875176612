import { IonButton, IonButtons, IonIcon, IonMenuToggle } from "@ionic/react";
import React from "react";

interface IProps {
  slot: "start" | "end";
  menu: string;
  icon: string;
}

export default function MenuButton(props: IProps): JSX.Element {
  return (
    <IonButtons slot={props.slot}>
      <IonMenuToggle menu={props.menu}>
        <IonButton>
          <IonIcon icon={props.icon} />
        </IonButton>
      </IonMenuToggle>
    </IonButtons>
  );
}
