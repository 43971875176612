import { useEffect, useState } from "react";
import { Container } from "../../helpers/container";
import { log } from "../../utils/log";
import ITripSummary from "./summary";
import TripSummaryService from "./summary_service";

export default class TripSummaryStore {
  constructor(
    container: Container,
    private readonly _summary = container.get(TripSummaryService)
  ) {}

  use(): [ITripSummary | undefined, () => Promise<void>] {
    const [summary, setSummary] = useState<ITripSummary>();
    const reload = () =>
      this._summary
        .getSummary()
        .then((summary) => setSummary(summary ?? undefined))
        .catch((ex) => log.error(ex));

    useEffect(() => {
      reload();
    }, []);

    return [summary, reload];
  }
}
