import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../core/vehicles/vehicle";
import i18n from "../../../i18n";
import VehicleItemSubtitle from "./item_subtitle";

interface IProps {
  vehicle: Vehicle;
}

export default function VehicleItemDistance(props: IProps): JSX.Element {
  const [t] = useTranslation();

  return useObserver(() => (
    <p>
      <VehicleItemSubtitle
        title={t("vehicles.distance")}
        caption={formatDistance(props.vehicle.distance)}
      />
    </p>
  ));
}

function formatDistance(distanceKm: number | null): string | null {
  if (!distanceKm) return i18n.t("unknown");
  if (distanceKm === Number.MAX_SAFE_INTEGER) return null;
  if (distanceKm < 0.001) return Math.floor(distanceKm * 100000) + "cm";
  if (distanceKm < 1) return Math.floor(distanceKm * 1000) + "m";

  return Math.floor(distanceKm * 100) / 100 + "km";
}
