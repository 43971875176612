import {
  chatbubbleEllipsesOutline,
  documentOutline,
  globeOutline,
  informationCircleOutline,
  newspaperOutline,
} from "ionicons/icons";
import { computed, observable, reaction } from "mobx";
import i18n from "../../i18n";
import { Api } from "../api";
import { AuthStore } from "../auth_store";
import { Container } from "../helpers/container";
import IGroup from "../rpc/models/group";
import { first } from "../utils/array";
import openExternal from "../utils/external";
import { log } from "../utils/log";
import IRoute from "./route";
import routes from "./routes";

export class RouteStore {
  @computed
  get routes(): IRoute[] {
    return [...routes, ...this._dynamicRoutes];
  }

  constructor(
    container: Container,
    private readonly _api = container.get(Api),
    private readonly _auth = container.get(AuthStore)
  ) {
    reaction(
      () => _auth.isLoggedIn,
      () => this.loadButtons()
    );
  }

  private async loadButtons() {
    const routes = [];

    if (!this._auth.isLoggedIn) {
      return;
    }

    try {
      const ecoGroup = first(
        await this._api.find("Group", {
          name: "eco-app",
        })
      );

      if (!ecoGroup) {
        return;
      }

      const children: IGroup[][] = await this._api.batch((factory) =>
        ecoGroup.children.map((x: IGroup) =>
          factory.find("Group", { id: x.id })
        )
      );

      for (const groups of children) {
        const route = this.getCustomRoute(groups[0]);
        if (route) {
          routes.push(route);
        }
      }
    } catch (ex) {
      log.error(ex);
    }

    this._dynamicRoutes = routes;
  }

  private getCustomRoute(group: IGroup): IRoute | null {
    const comment = group.comments?.trim() ?? "";
    if (!comment.match(/^http[s]?:\/\//)) {
      return null;
    }

    let icon = documentOutline;
    let title = group.name;
    let subtitle = "";

    switch (group.name) {
      case "Fairfleet":
        icon = globeOutline;
        subtitle = i18n.t("main.fairfleet.subtitle");
        break;
      case "Feedback":
        icon = chatbubbleEllipsesOutline;
        title = i18n.t("main.feedback.title")
        subtitle = i18n.t("main.feedback.subtitle");
        break;
    }

    return {
      icon,
      title,
      subtitle,
      onClick: () => openExternal(comment),
      canNavigate: () => true,
    };
  }

  @observable _dynamicRoutes: IRoute[] = [];
}
