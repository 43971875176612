import IRpcResponseError from "./response_error";

export enum RpcErrorType {
  OverLimitException,
  InvalidUserException,
}

export class RpcError<TErrorData = any> extends Error {
  constructor(
    public readonly code: number,
    public readonly message: string,
    public readonly data?: TErrorData,
    public readonly type: RpcErrorType | null = RpcError.typeFromData(data)
  ) {
    super(message);
  }

  public static fromResponseError<T = any>(res: IRpcResponseError<T>) {
    return new RpcError<T>(res.code, res.message, res.data);
  }

  public static typeFromData(data?: any): RpcErrorType | null {
    switch (data?.type) {
      case "OverLimitException":
        return RpcErrorType.OverLimitException;
      case "InvalidUserException":
        return RpcErrorType.InvalidUserException;
      default:
        return null;
    }
  }
}

declare global {
  interface Error {
    getRpcType(): RpcErrorType | null;
  }
}

Error.prototype.getRpcType = function() {
  const self = this as Error | RpcError;

  if ("type" in self)
    return self.type;

  return null;
}