import React from "react";

interface IProps {
  title: string;
  caption: string | null;
}

export default function VehicleItemSubtitle(props: IProps): JSX.Element {
  return (
    <>
      {props.title} - <b>{props.caption}</b>
    </>
  );
}
