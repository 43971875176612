import { Api } from "../api";
import { AuthStore } from "../auth_store";
import { DriversStore } from "../drivers/drivers_store";
import { Container } from "../helpers/container";
import IDevice from "../rpc/models/device";
import IDeviceStatusInfo from "../rpc/models/device_status_info";
import { first } from "../utils/array";
import { Vehicle } from "./vehicle";

export class VehiclesService {
  constructor(
    container: Container,
    private readonly _api = container.get(Api),
    private readonly _auth = container.get(AuthStore),
    private readonly _drivers = container.get(DriversStore)
  ) {}

  public async findVehicles(
    search: string,
    position?: Position
  ): Promise<Vehicle[]> {
    if (!this._auth.me) return [];

    return await this.getVehiclesFromDevices(
      await this.findDevices(search, position),
      position
    );
  }

  public async getVehicle(id: string): Promise<Vehicle> {
    type Result = [IDevice[], IDeviceStatusInfo[]];
    const result: Result = await this._api.batch((factory) => [
      factory.find("Device", { id }),
      factory.find("DeviceStatusInfo", { deviceSearch: { id } }),
    ]);

    const device = first(result[0]);
    const status = first(result[1]);

    if (!device) {
      throw new Error(`Device with id "${id}" not found.`);
    }

    return new Vehicle(device, status, null, this._api, this._drivers);
  }

  private async getVehiclesFromDevices(
    devices: IDevice[],
    position?: Position
  ): Promise<Vehicle[]> {
    devices = devices.filter(
      (value, index, self) =>
        Date.parse(value.activeTo) >= Date.now() &&
        self.findIndex((other) => other.id === value.id) === index
    );

    if (devices.length === 0) {
      return [];
    }

    const statuses = (
      await this._api.batch((factory) =>
        devices.map((device) =>
          factory.find("DeviceStatusInfo", {
            deviceSearch: { id: device.id },
          })
        )
      )
    ).flat();

    const statusMap = new Map<string, IDeviceStatusInfo>();

    for (const status of statuses) {
      statusMap.set(status.device.id, status);
    }

    return devices.map(
      (device) =>
        new Vehicle(
          device,
          statusMap.get(device.id) ?? null,
          position ?? null,
          this._api,
          this._drivers
        )
    );
  }

  public findDevices(search: string, position?: Position): Promise<IDevice[]> {
    if (search.length > 0) return this.findDevicesBySearch(search);
    if (position) return this.findDevicesByPosition(position.coords);

    return this.getDevices(50);
  }

  public getDevices(limit: number): Promise<IDevice[]> {
    return this._api.get("Device", limit);
  }

  public async findDevicesBySearch(search: string): Promise<IDevice[]> {
    return (
      await this._api.batch((factory) => [
        factory.find("Device", { name: `%${search}%` }, 10),
        factory.find("Device", { serialNumber: `%${search}%` }, 10),
        factory.find("Device", { licensePlate: `%${search}%` }, 10),
      ])
    ).flat();
  }

  public async findDevicesByPosition(
    coordinates: Coordinates
  ): Promise<IDevice[]> {
    const position = {
      x: coordinates.longitude,
      y: coordinates.latitude,
    };

    const results = await this._api.find("DeviceStatusInfo", {
      position,
      closestAssetLimit: 10,
    });

    if (results.length === 0) {
      return [];
    }

    const devices = await this._api.batch((factory) =>
      results.map((status) => factory.find("Device", { id: status.device.id }))
    );

    return devices.flat();
  }
}
