import { IonButton, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import TripState from "../../core/trip/trip_state";
import { useApp } from "../../hooks/app_store";
import EventsCard from "./cards/events_card";
import PendingCard from "./cards/pending_card";
import ScoreCard from "./cards/score_card";
import "./page_trip.scss";

export default function TripPage(): JSX.Element {
  const [t] = useTranslation();
  const { score, status } = useApp();
  const [value, isOffline, events] = score.useScore();
  const history = useHistory();

  return useObserver(() => (
    <IonContent>
      <IonGrid style={{ marginBottom: 80 }}>
        {status.state === TripState.Active && (
          <IonRow>
            <IonCol>
              <ScoreCard score={value} isOffline={isOffline} />
            </IonCol>
          </IonRow>
        )}

        {status.state === TripState.Pending && (
          <IonRow>
            <IonCol>
              <PendingCard />
            </IonCol>
          </IonRow>
        )}

        <IonRow>
          <IonCol>
            <EventsCard events={events} />
          </IonCol>
        </IonRow>
      </IonGrid>

      <div className="eco-buttons">
        <IonButton
          expand="block"
          size="large"
          onClick={() => history.push("/trip_summary")}
        >
          {t("trip.stop")}
        </IonButton>
      </div>
    </IonContent>
  ));
}
