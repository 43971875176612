const config = {
  group: "eco-app",
  permissions: {
    menu: {
      news: "News",
      vehicles: "Fahrer",
      dashboard: "Dashboard",
    },
    actions: {
      private: "Personal_Mode",
    },
  },
  score: {
    faces: "http://eco.fairfleet.de/ecoapp/faces/{{score}}.svg",
    weight_group_prefix: "Gewicht ",
  },
};

export default config;
