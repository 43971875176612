import { first } from "../utils/array";
import { Api } from "../api";
import { getCachedResolver } from "../helpers/cache";
import IDevice from "../rpc/models/device";
import IDriverChange from "../rpc/models/driver_change";
import IUser from "../rpc/models/user";
import { Vehicle } from "../vehicles/vehicle";

export class Driver {
  public get name(): string {
    if (this.user.firstName?.length >= 0) {
      return this.user.firstName + " " + this.user.lastName;
    }

    return this.user.name;
  }

  constructor(
    public readonly user: IUser,
    private readonly _api: Api,
  ) { }
  
  public async getVehicle(): Promise<{ id: string } | null> {
    const change = first(await this._api.find("DriverChange", {
      userSearch: { id: this.user.id },
      includeOverlappedChanges: true,
    }));

    if (!change) return null;
    if (change.driver?.id !== this.user.id) return null;

    const vehicleChange = first(await this._api.find("DriverChange", {
      deviceSearch: { id: change.device.id },
      includeOverlappedChanges: true,
    }));

    if (!vehicleChange) return null;
    if (vehicleChange.driver?.id !== this.user.id) return null;

    return vehicleChange.device;
  }

  public getDriverChanges = getCachedResolver(
    (opts: { fromDate?: Date; toDate?: Date; vehicle?: Vehicle }) =>
      this._api.find("DriverChange", {
        toDate: opts.toDate?.toISOString(),
        fromDate: opts.fromDate?.toISOString(),
        userSearch: { id: this.user.id },
        deviceSearch: opts.vehicle
          ? { id: opts.vehicle?.device.id }
          : undefined,
        includeOverlappedChanges: true,
      })
  );

  public async getLatestDriverChange(opts?: {
    fromDate?: Date;
    toDate?: Date;
  }): Promise<IDriverChange | null> {
    const changes = await this.getDriverChanges(opts ?? {});
    changes.sort((a, b) => Date.parse(b.dateTime) - Date.parse(a.dateTime));
    return first(changes);
  }
}
