import {
  IonApp,
  IonContent,
  IonHeader,
  IonPage,
  IonSplitPane,
} from "@ionic/react";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { useObserver } from "mobx-react-lite";
import "mobx-react-lite/batchingForReactDom";
import React, { useEffect } from "react";
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import LicensePage from "./components/legal/license_page";
import MainMenu from "./components/navigation/main/menu";
import { Alert } from "./components/navigation/prompt/alert";
import { Loading } from "./components/navigation/prompt/loading";
import Toasts from "./components/navigation/prompt/toasts";
import ToolBar from "./components/navigation/toolbar";
import SettingsMenu from "./components/settings/menu";
import VersionCheck from "./components/version_check";
import IRoute from "./core/routes/route";
import TripState from "./core/trip/trip_state";
import { AppProvider, useApp } from "./hooks/app_store";
import "./i18n";
/* Theme variables */
import "./theme/variables.scss";


export default function App() {
  return (
    <IonApp>
      <HashRouter>
        <AppProvider>
          <Content />
        </AppProvider>
      </HashRouter>
    </IonApp>
  );
}

export function Content(): JSX.Element {
  const app = useApp();

  useEffect(() => {
    app.auth.checkSession();
  });

  return useObserver(() => (
    <IonSplitPane contentId="main">
      <MainMenu />
      <SettingsMenu />

      <IonPage id="main">
        <IonHeader>
          <ToolBar />
        </IonHeader>
        <IonContent>
          <Alert />
          <Loading />
          <Toasts />
          <VersionCheck />

          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/licenses" component={LicensePage} />

            {app.route.routes
              ?.filter((route) => !!route.link && !!route.component)
              .map((route, key) => (
                <Route
                  key={key}
                  path={route.link}
                  exact
                  component={() => <RouteWrapper route={route} />}
                />
              ))}
          </Switch>
        </IonContent>
      </IonPage>
    </IonSplitPane>
  ));
}

function Home() {
  const { auth, status } = useApp();

  return useObserver(() => {
    if (!auth.isLoggedIn) {
      return <Redirect to="/login" />;
    }

    if (status.state === TripState.Complete) {
      return <Redirect to="/trip_summary" />;
    }

    if (status.state !== TripState.None) {
      return <Redirect to="/trip" />;
    }

    return <Redirect to="/vehicles" />;
  });
}

function RouteWrapper(props: { route: IRoute }): JSX.Element {
  const app = useApp();
  const route = props.route;
  const history = useHistory();

  return useObserver(() => {
    if (!route.canNavigate(app, history)) return <Redirect to="/" />;
    if (!route.component) return <></>;

    const Component = route.component(app);
    return <Component />;
  });
}
