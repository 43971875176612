import { Api } from "../../api";
import { AuthStore } from "../../auth_store";
import GroupsService from "../../groups/groups_service";
import { Container } from "../../helpers/container";
import IExceptionEvent from "../../rpc/models/exception_event";
import ITrip from "../../rpc/models/trip";
import IUser from "../../rpc/models/user";
import { first, last } from "../../utils/array";
import Duration from "../../utils/duration";
import ScoreService from "../score_service";
import ITripSummary from "./summary";

export default class TripSummaryService {
  constructor(
    container: Container,
    private readonly _api = container.get(Api),
    private readonly _auth = container.get(AuthStore),
    private readonly _score = container.get(ScoreService),
    private readonly _groups = container.get(GroupsService)
  ) {}

  async getSummary(): Promise<ITripSummary | null> {
    const user = this._auth.me?.user;
    const config = await this._groups.getCompanyConfig();

    if (!user) {
      return null;
    }

    const toDate = new Date();
    const fromDate = new Date(
      toDate.getTime() - config.summaryTimeSpan.totalMilliseconds
    );

    const [trips, idleEvents] = await this.fetchTripsAndEvents(
      user,
      toDate,
      fromDate
    );

    if (trips.length === 0) {
      return null;
    }

    const [, exceptionEvents] = await this._score.getScore(
      user,
      fromDate,
      toDate
    );

    const dateStart = new Date(
      first(trips)?.start ?? fromDate
    ).toLocaleString();
    const dateEnd = new Date(last(trips)?.stop ?? toDate).toLocaleString();

    let totalDistance = 0;
    let totalIdleDuration = 0;
    let totalAverageSpeed = 0;

    for (const trip of trips) {
      totalDistance += trip.distance;
      totalAverageSpeed += trip.averageSpeed;
    }

    for (const event of idleEvents) {
      totalIdleDuration +=
        Date.parse(event.activeTo) - Date.parse(event.activeFrom);
    }

    const idleDuration = new Duration(totalIdleDuration);
    const idleFuelUsed = idleDuration.totalHours * 3.4;
    const idleCo2Emitted = idleFuelUsed * 2.65;
    const eventsPerHundredKm = (exceptionEvents.length / totalDistance) * 100;
    const toLocaleString = (value: number) =>
      (isNaN(value) ? 0 : value).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });

    return {
      dateEnd,
      dateStart,

      distance: toLocaleString(totalDistance),

      idleTime: idleDuration.toLocaleString(),
      idleFuelUsed: toLocaleString(idleFuelUsed),
      idleCo2Emitted: toLocaleString(idleCo2Emitted),

      averageSpeed: toLocaleString(totalAverageSpeed / trips.length),

      events: exceptionEvents,
      eventsPerHundredKm: toLocaleString(eventsPerHundredKm),
    };
  }

  private async fetchTripsAndEvents(
    user: IUser,
    toDate: Date,
    fromDate: Date
  ): Promise<[ITrip[], IExceptionEvent[]]> {
    type Result = [ITrip[], IExceptionEvent[]];
    const result: Result = await this._api.batch((factory) => [
      factory.find("Trip", {
        toDate: toDate.toISOString(),
        fromDate: fromDate.toISOString(),
        userSearch: { id: user.id },
      }),
      factory.find("ExceptionEvent", {
        toDate,
        fromDate,
        userSearch: { id: user.id },
        ruleSearch: { id: "RuleIdlingId" },
      }),
    ]);

    result[0].sort((a, b) => Date.parse(a.fromDate) - Date.parse(b.fromDate));

    return result;
  }
}
