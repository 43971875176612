import { Ndef, NFC } from "@ionic-native/nfc";
import { autorun, observable, reaction } from "mobx";
import { useEffect } from "react";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import i18n from "../../i18n";
import { AuthStore } from "../auth_store";
import GroupsService from "../groups/groups_service";
import { Container } from "../helpers/container";
import { PromptStore } from "../prompt_store";
import StatusStore from "../trip/status_store";
import { log } from "../utils/log";
import { Vehicle } from "./vehicle";
import { VehiclesService } from "./vehicles_service";

const MIME_TYPE = "application/ecoapp-vehicle";

export class NfcStore {
  @observable
  public canWrite = true;

  constructor(
    container: Container,
    auth = container.get(AuthStore),
    groups = container.get(GroupsService),
    private readonly _status = container.get(StatusStore),
    private readonly _prompt = container.get(PromptStore),
    private readonly _vehicles = container.get(VehiclesService)
  ) {
    reaction(
      () => auth.me?.user,
      async (me) =>
        (this.canWrite =
          !!me && (await groups.hasClearance(me, "CreateProgrammingKey")))
    );
  }

  public async writeTag(vehicle: Vehicle): Promise<void> {
    const dialog = this._prompt.showCancel(
      i18n.t("vehicles.nfc.confirm.write.title"),
      i18n.t("vehicles.nfc.confirm.write.subitle", {
        vehicleName: vehicle.device.name,
      })
    );

    try {
      const write = this.writeTagNoCancel(vehicle, dialog.cancel);
      await dialog.promise;
      write.unsubscribe();
    } catch {
      // swallow
    }
  }

  public useTagRead(): void {
    useEffect(() => {
      let sub: Subscription | null = null;

      const done = autorun(() => {
        if (this._writing) {
          sub?.unsubscribe();
        } else {
          sub?.unsubscribe();
          sub = this.readTag();
        }
      });

      return () => {
        done();
        sub?.unsubscribe();
      };
    });
  }

  private readTag() {
    return NFC.addNdefListener().subscribe(async (data) => {
      if (this._writing) return;
      if (!data.tag.ndefMessage) return;
      for (const message of data.tag.ndefMessage) {
        if (this._writing) return;
        if (message.tnf !== 2) continue;

        try {
          const type = new Buffer(message.type).toString("utf8");
          if (type !== MIME_TYPE) continue;

          const payload = new Buffer(message.payload).toString("utf8");
          log.breadcrumb("nfc", "read", { type, payload });
          const vehicle = await this._vehicles.getVehicle(payload);

          await this._status.assignTo(vehicle, true);
        } catch (ex) {
          this._prompt.showErrorAlert(
            i18n.t("vehicles.nfc.error.read.title"),
            i18n.t("vehicles.nfc.error.read.subtitle"),
            ex
          );
        }
      }
    }, log.error);
  }

  private writeTagNoCancel(vehicle: Vehicle, cancel: () => void) {
    this._writing = true;

    return NFC.addNdefListener()
      .pipe(first())
      .subscribe(
        async () => {
          try {
            log.breadcrumb("nfc", "write", { type: MIME_TYPE, vehicle });
            const payload = Ndef.mimeMediaRecord(MIME_TYPE, vehicle.device.id);
            await NFC.write([payload]);

            this._prompt.showToast({
              color: "warning",
              message: i18n.t("vehicles.nfc.confirm.wrote", {
                vehicleName: vehicle.device.name,
              }),
              position: "top",
              duration: 3000,
            });

            cancel();

            this._prompt.showAlert(
              i18n.t("vehicles.nfc.error.write.title"),
              i18n.t("vehicles.nfc.error.write.subtitle", {
                vehicleName: vehicle.device.name,
              })
            );
          } catch (ex) {
            cancel();
            this._prompt.showErrorAlert(
              i18n.t("vehicles.nfc.error.write.title"),
              i18n.t("vehicles.nfc.error.write.subtitle", {
                vehicleName: vehicle.device.name,
              }),
              ex
            );
          }
        },
        () => null,
        () => setTimeout(() => (this._writing = false), 1000)
      );
  }

  @observable private _writing = false;
}
