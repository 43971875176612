import { computed, observable } from "mobx";
import config from "../../config";
import { Api } from "../api";
import { AuthStore } from "../auth_store";
import { Container } from "../helpers/container";
import IExceptionEvent from "../rpc/models/exception_event";
import IRule from "../rpc/models/rule";
import IUser from "../rpc/models/user";
import { first } from "../utils/array";

const CONFIG_INTERVAL = 2;

interface IScoreConfig {
  limits: [number, number][];
  seconds: number;
}

export default class ScoreService {
  @computed
  public get interval(): number {
    return (this._config?.seconds ?? 0) * 1000;
  }

  constructor(
    container: Container,
    private readonly _api = container.get(Api),
    private readonly _auth = container.get(AuthStore)
  ) {}

  public async getScore(
    user: IUser,
    from: Date,
    to: Date
  ): Promise<[number, IExceptionEvent[]]> {
    const events = await this.getEvents(user, from, to);
    const weight = this.getWeightForEvents(events);
    const config = await this.getCompanyConfig();
    const score = this.getScoreFromWeightTotal(weight, config);
    return [score, events];
  }

  private async getEvents(
    user: IUser,
    fromDate: Date,
    toDate: Date
  ): Promise<IExceptionEvent[]> {
    const result = [];
    const events = await this._api.find("ExceptionEvent", {
      toDate: toDate.toISOString(),
      fromDate: fromDate.toISOString(),
      userSearch: { id: user.id },
    });

    const ruleMap = await this.getRulesMap(
      events.map((event) => event.rule.id)
    );

    for (const event of events) {
      const rule = ruleMap.get(event.rule.id);
      if (!rule?.name.toUpperCase().startsWith("ECO ")) continue;

      result.push({
        ...event,
        rule,
      });
    }

    return result;
  }

  private async getRulesMap(ruleIds: string[]): Promise<Map<string, IRule>> {
    const result = new Map<string, IRule>();
    if (ruleIds.length === 0) {
      return result;
    }

    const response = await this._api.batch((factory) =>
      ruleIds.map((id) => factory.find("Rule", { id }))
    );

    for (const rules of response) {
      const rule = first(rules);
      if (!rule) continue;
      if (!rule.name.startsWith("ECO ")) continue;

      result.set(rule.id, rule);
    }

    return result;
  }

  private getWeightForEvents(events: IExceptionEvent[]) {
    return events.reduce((result, event) => {
      result += this.getWeightForEvent(event);
      return result;
    }, 0);
  }

  private getWeightForEvent(event: IExceptionEvent) {
    const rule: IRule | undefined = event?.rule;
    if (!rule) return 0;

    const weight = parseFloat(rule.comment.trim());
    if (weight && !isNaN(weight)) return weight;

    return 0;
  }

  private getScoreFromWeightTotal(total: number, config: IScoreConfig): number {
    for (let i = config.limits.length - 1; i > 0; i--) {
      const [weight, score] = config.limits[i];
      if (total >= weight) {
        return score;
      }
    }

    return 1;
  }

  private async getCompanyConfig(): Promise<IScoreConfig> {
    if (this._config && this._configSkips < CONFIG_INTERVAL) {
      return this._config;
    }

    this._configSkips = 0;

    const group = first(await this._api.find("Group", { name: config.group }));
    if (!group) throw new Error(`Group ${config.group} not defined in geotab`);
    const comments = group.comments;

    const limits: [number, number][] = [];
    let seconds = 0;

    for (const line of comments.split("\n")) {
      const match = line.match(/(\d+)\s*,\s*Grafik\s*(\d+)/);
      if (!match) {
        const match = line.match(/(\d+)\s*,\s*Seconds/);
        if (!match) continue;
        const [, secondsRaw] = match;

        seconds = parseInt(secondsRaw, 10);
        if (isNaN(seconds)) seconds = 0;
        continue;
      }

      const [, weightRaw, scoreRaw] = match;

      const score = parseInt(scoreRaw, 10);
      const weight = parseInt(weightRaw, 10);

      if (isNaN(score)) continue;
      if (isNaN(weight)) continue;

      limits.push([weight, score]);
    }

    limits.sort((a, b) => a[0] - b[0]);

    return (this._config = { seconds, limits });
  }

  @observable
  private _config?: IScoreConfig;
  private _configSkips = 0;
}
