import { IonLabel, IonList, IonListHeader } from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../hooks/app_store";
import VehicleItem from "./item";

export default function VehicleList(): JSX.Element {
  const [t] = useTranslation();
  const app = useApp();
  
  app.vehicles.useFiltered();

  return useObserver(() => (
    <IonList>
      <IonListHeader>
        <IonLabel>{t("vehicles.title")}</IonLabel>
      </IonListHeader>
      {app.vehicles.all
        .slice()
        .sort(
          (a, b) =>
            (a.distance ?? Number.MAX_SAFE_INTEGER) -
            (b.distance ?? Number.MAX_SAFE_INTEGER)
        )
        .map((vehicle) => (
          <VehicleItem key={vehicle.device.id} vehicle={vehicle} />
        ))}
    </IonList>
  ));
}
