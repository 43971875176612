import { IonLoading } from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useApp } from "../../../hooks/app_store";

export function Loading(): JSX.Element {
  const { prompt } = useApp();
  return useObserver(() => (
    <IonLoading
      isOpen={prompt.isLoadingOpen}
      message={prompt.loading?.message ?? ""}
    />
  ));
}
