import { IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export default function PasswordInput(props: IProps): JSX.Element {
  const [t] = useTranslation();
  const [hidden, setHidden] = useState(true);

  return (
    <IonItem className={props.className}>
      <IonLabel position="floating">{t("login.password")}</IonLabel>
      <IonInput
        type={hidden ? "password" : "text"}
        value={props.value}
        name="password"
        inputMode="text"
        enterkeyhint="done"
        autocomplete="current-password"
        onIonChange={(evt) =>
          evt.detail.value ? props.onChange(evt.detail.value) : null
        }
      />
      <IonIcon
        slot="end"
        style={{ height: "80%" }}
        icon={hidden ? eyeOutline : eyeOffOutline}
        onClick={() => setHidden(!hidden)}
      />
    </IonItem>
  );
}
