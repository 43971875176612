import { Api } from "../../../core/api";
import { IRpcEndPoint } from "../../../core/rpc/endpoint";
import openExternal from "../../../core/utils/external";

export default function OpenDashboardPage(api: Api): void {
  const redirect = getDashboardUrl(api.endPoint);
  if (!redirect) return;

  openExternal(redirect);
}

function getDashboardUrl(endPoint?: IRpcEndPoint): string | null {
  const credentials = endPoint?.credentials;
  if (!credentials) return null;
  const { database, sessionId, userName } = credentials;
  if (!database) return null;

  return (
    endPoint?.url.replace("apiv1", database) +
    "/#dashboard,token:(" +
    [
      `'userName':'${userName ?? ""}'`,
      `'sessionId':'${sessionId ?? ""}'`,
      `'database':'${database ?? ""}'`,
    ].join(",") +
    ")"
  );
}
