import { IonContent } from "@ionic/react";
import React from "react";
import LicenseList from "./license_list";

export default function LicensePage(): JSX.Element {
  return (
    <IonContent>
      <LicenseList />
    </IonContent>
  );
}
