import { menuController } from "@ionic/core";
import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import IRoute from "../../../core/routes/route";
import { useApp } from "../../../hooks/app_store";

interface IMenuItemProps {
  route: IRoute;
}

export default function MenuItem(props: IMenuItemProps): JSX.Element {
  const app = useApp();
  const route = props.route;
  const atRoute = useRouteMatch(route.link ?? "/");
  const history = useHistory();

  const onClickWrapped = () => {
    menuController.close();

    if (route.link) history.push(route.link);
    if (route.onClick) route.onClick(app);
  };

  return useObserver(() => {
    const canShow = route.canShow ? route.canShow(app) : true;
    const canNavigate = route.canNavigate(app);

    if (!canShow) return <></>;
    if (!canNavigate) return <></>;

    return (
      <IonItem
        onClick={onClickWrapped}
        disabled={!!route.link ? atRoute?.isExact : false}
      >
        <IonIcon icon={route.icon} color="primary" slot="start" />
        <IonLabel>
          <h2>{route.title}</h2>
          <p>{route.subtitle}</p>
        </IonLabel>
      </IonItem>
    );
  });
}
