import { log } from "../utils/log";
import ICredentials from "./models/credentials";
import { EntityNameMap, SearchEntityNameMap } from "./models/entity_map";
import ILoginResult from "./models/login_result";
import IFeedResult from "./feed_result";
import IRpcRequest from "./request";

export class RpcRequestFactory {
  public auth(
    credentials: Partial<ICredentials>
  ): IRpcRequest<Partial<ICredentials>, ILoginResult> {
    return {
      method: "Authenticate",
      params: credentials,
      jsonrpc: "2.0",
    };
  }

  public get<TType extends keyof EntityNameMap>(
    typeName: TType,
    resultsLimit?: number
  ): IRpcRequest<any, EntityNameMap[TType][]> {
    return {
      method: "Get",
      params: {
        typeName,
        resultsLimit,
      },
      jsonrpc: "2.0",
    };
  }

  public getFeed<
    TType extends keyof SearchEntityNameMap,
    TEntity = SearchEntityNameMap[TType]["type"],
    TSearchEntity = SearchEntityNameMap[TType]["search"]
  >(
    typeName: TType,
    search?: TSearchEntity,
    fromVersion?: string,
    resultsLimit?: number
  ): IRpcRequest<any, IFeedResult<TEntity[]>> {
    return {
      method: "GetFeed",
      params: {
        typeName,
        search,
        fromVersion,
        resultsLimit,
      },
      jsonrpc: "2.0",
    };
  }

  public find<
    TType extends keyof SearchEntityNameMap,
    TEntity = SearchEntityNameMap[TType]["type"],
    TSearchEntity = SearchEntityNameMap[TType]["search"]
  >(
    typeName: TType,
    search: Partial<TSearchEntity>,
    resultsLimit?: number
  ): IRpcRequest<any, TEntity[]> {
    return {
      method: "Get",
      params: {
        search,
        typeName,
        resultsLimit,
      },
      jsonrpc: "2.0",
    };
  }

  public create<
    TType extends keyof EntityNameMap,
    TEntity extends EntityNameMap[TType]
  >(
    typeName: TType,
    entity: Partial<Omit<TEntity, "id">>
  ): IRpcRequest<any, string> {
    return {
      method: "Add",
      params: {
        entity,
        typeName,
      },
      jsonrpc: "2.0",
    };
  }

  public update<
    TType extends keyof EntityNameMap,
    TEntity extends EntityNameMap[TType]
  >(typeName: TType, entity: Partial<TEntity>): IRpcRequest<any, string> {
    return {
      method: "Set",
      params: {
        entity,
        typeName,
      },
      jsonrpc: "2.0",
    };
  }

  public delete<
    TType extends keyof EntityNameMap,
    TEntity extends EntityNameMap[TType]
  >(typeName: TType, entity: Partial<TEntity>): IRpcRequest<any, void> {
    return {
      method: "Remove",
      params: {
        entity,
        typeName,
      },
      jsonrpc: "2.0",
    };
  }
}
