import { IonProgressBar, IonText } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/logo.svg";
import "./nfc_button.scss";
import { useApp } from "../../../hooks/app_store";

export default function NfcButton(): JSX.Element {
  const [t] = useTranslation();
  const { nfc } = useApp();

  nfc.useTagRead();

  return (
    <div className="eco-nfc-container">
      <IonProgressBar type="indeterminate" color="primary" />
      <div className="eco-nfc-button">
        <img src={logo} />
        <IonText color="dark">{t("vehicles.scan_nfc")}</IonText>
      </div>
    </div>
  );
}
