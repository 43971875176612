import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { leafOutline } from "ionicons/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import ITripSummary from "../../../core/trip/summary/summary";

interface IProps {
  summary?: ITripSummary;
  loading: boolean;
}

export default function SummaryDetailsCard(props: IProps): JSX.Element {
  const [t] = useTranslation();
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{t("trip.details.title")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItem>
          <IonLabel>
            <h3>{t("trip.details.events")}/100Km</h3>
            <p>{props.summary?.eventsPerHundredKm}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h3>{t("trip.details.tripStart")}</h3>
            <p>{props.summary?.dateStart}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h3>{t("trip.details.tripEnd")}</h3>
            <p>{props.summary?.dateEnd}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h3>{t("trip.details.distance")}</h3>
            <p>{props.summary?.distance}km</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h3>{t("trip.details.averageSpeed")}</h3>
            <p>{props.summary?.averageSpeed}km/h</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h3>{t("trip.details.idleTime")}</h3>
            <p>{props.summary?.idleTime}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h3>{t("trip.details.idleFuelUsed")}</h3>
            <p>{props.summary?.idleFuelUsed}L</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h3>{t("trip.details.idleCo2")}</h3>
            <p>
              <IonIcon slot="start" icon={leafOutline} />
              {" " + props.summary?.idleCo2Emitted}Kg CO
              <sub>2</sub>
            </p>
          </IonLabel>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
}
