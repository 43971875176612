import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import React from "react";
import { useApp } from "../../../hooks/app_store";

export default function VehiclesRefresh(): JSX.Element {
  const { vehicles } = useApp();
  return (
    <IonRefresher
      slot="fixed"
      onIonRefresh={(evt: CustomEvent<RefresherEventDetail>) => {
        vehicles.reload().then(() => evt.detail.complete());
      }}
    >
      <IonRefresherContent />
    </IonRefresher>
  );
}
