import { IonToast } from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useApp } from "../../../hooks/app_store";

export default function Toasts(): JSX.Element {
  const { prompt } = useApp();
  return useObserver(() => (
    <>
      {prompt.toasts.slice().map((toast, idx) => (
        <IonToast
          key={toast.id}
          isOpen
          onDidDismiss={() => prompt.toasts.splice(idx, 1)}
          {...toast}
        />
      ))}
    </>
  ));
}
