interface ICoordinates {
  latitude: number;
  longitude: number;
}

/**
 * 
 * @param from From coordinate
 * @param to To coordinate
 */
export default function getDistance<
  TCoordinatesFrom extends ICoordinates | Readonly<ICoordinates>,
  TCoordinatesTo extends ICoordinates | Readonly<ICoordinates>
>(
  from: TCoordinatesFrom,
  to: TCoordinatesTo
): number {
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 -
    c((to.latitude - from.latitude) * p) / 2 +
    (c(from.latitude * p) *
      c(to.latitude * p) *
      (1 - c((to.longitude - from.longitude) * p))) /
      2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}
