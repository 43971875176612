import {
  addBreadcrumb,
  captureEvent,
  captureException,
  Severity,
} from "@sentry/react";
import { observe, toJS } from "mobx";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const console = process.env.NODE_ENV?.startsWith("dev")
  ? window.console
  : require("logrocket");

export class Log {
  public debug = (message: string, extra?: any) => {
    extra = toJS(extra);
    this.log(Severity.Debug, message, extra);
  };

  public info = (message: string, extra?: any) => {
    extra = toJS(extra);
    this.log(Severity.Info, message, extra);
  };

  public warn = (message: string, extra?: any) => {
    extra = toJS(extra);
    this.log(Severity.Warning, message, extra);
  };

  public error = (ex: Error) => {
    this.catch(ex);
  };

  public breadcrumb = (type: string, message: string, data?: any) => {
    data = toJS(data);
    console.debug(message, { type, data })
    addBreadcrumb({ type, message: type, data });
  };

  private log = (level: Severity, message: string, extra: any) => {
    switch (level) {
      case Severity.Error:
      case Severity.Fatal:
      case Severity.Critical:
      case Severity.Warning:
        console.error(message, extra);
        captureException({ extra, level, message });

        break;
      case Severity.Log:
        console.log(message, extra);
        break;
      case Severity.Info:
        console.info(message, extra);
        break;
      case Severity.Debug:
        console.debug(message, extra);
        break;
      default:
        console.log(message, extra);
        break;
    }
  };

  private catch = (ex: Partial<Error>) => {
    if (ex.stack) {
      ex = new Error(String(ex));
    }

    if (process.env.NODE_ENV?.startsWith("dev")) {
      console.error(ex);
      return;
    }

    if ("captureException" in console) {
      console.captureException(ex);
    }

    captureException(ex);
  };
}

export const log = new Log();
