import { AlertButton } from "@ionic/react";
import { computed, observable } from "mobx";
import i18n from "../i18n";
import { Container } from "./helpers/container";
import { log } from "./utils/log";

interface IAlert {
  title?: string;
  message?: string;
  isOpen: boolean;
  buttons?: AlertButton[];
}

interface ILoading {
  message: string;
}

interface IToast {
  id: string;
  header?: string;
  message?: string;
  cssClass?: string | string[];
  duration?: number;
  position?: "top" | "bottom" | "middle";
  translucent?: boolean;
  animated?: boolean;
  color?: string;
}

type Cancel = { cancel: () => void; promise: Promise<void> };
type LoadingDismiss = () => void;

export class PromptStore {
  @observable public alert: IAlert = { isOpen: false };
  @observable public loading: ILoading | null = null;
  @observable public toasts: IToast[] = [];

  @computed
  public get isAlertOpen(): boolean {
    return !!this.alert;
  }

  @computed
  public get isLoadingOpen(): boolean {
    return !!this.loading;
  }

  constructor(container: Container) {
    // pass
  }

  public showAlert(
    title: string,
    message: string,
    ...buttonNames: string[]
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const buttons: AlertButton[] = [];

      for (const buttonName of buttonNames) {
        buttons.push({
          text: buttonName,
          handler: () => resolve(buttonName),
        });
      }

      this.alert = {
        title,
        message,
        buttons,
        isOpen: true,
      };
    });
  }

  public async showConfirm(title: string, subtitle: string): Promise<boolean> {
    const ok = i18n.t("ok");
    const cancel = i18n.t("cancel");
    const button = await this.showAlert(title, subtitle, ok, cancel);

    return button === ok;
  }

  public showCancel(title: string, message: string): Cancel {
    const ret: Cancel = {
      cancel: () => null,
      promise: Promise.resolve(),
    };

    const cancel = i18n.t("cancel");
    const signal = new Promise((resolve, reject) => {
      ret.cancel = () => reject(new Error("Cancelled"));
    });

    ret.promise = new Promise((resolve, reject) => {
      signal.catch(() => {
        this.alert.isOpen = false;
        reject();
      });

      this.alert = {
        title,
        message,
        buttons: [{ text: cancel, handler: resolve }],
        isOpen: true,
      };
    });

    return ret;
  }

  public showErrorAlert(titleKey: string, messageKey: string, ex: Error): void {
    this.alert.title = titleKey;
    this.alert.message = messageKey;
    this.alert.isOpen = true;
    log.error(ex);
  }

  public showLoading(messageKey: string): LoadingDismiss {
    this.loading = { message: messageKey };
    return () => (this.loading = null);
  }

  public showToast(toast: Omit<IToast, "id">): void {
    this.toasts.push({
      id: new Date().toISOString(),
      ...toast,
    });
  }
}
