import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { createOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DatabaseAlert from "./database_alert";

export default function DatabaseMenuItem(): JSX.Element {
  const [t] = useTranslation();
  const [isAlertOpen, setAlertOpen] = useState(false);

  return (
    <>
      <DatabaseAlert isOpen={isAlertOpen} onClose={() => setAlertOpen(false)} />
      <IonItem button onClick={() => setAlertOpen(true)}>
        <IonLabel>
          <h2>{t("settings.database.title")}</h2>
          <p>{t("settings.database.subtitle")}</p>
        </IonLabel>
        <IonIcon icon={createOutline} slot="end" />
      </IonItem>
    </>
  );
}
