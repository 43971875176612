import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import banner from "../../assets/banner.svg";
import openExternal from "../../core/utils/external";
import { useApp } from "../../hooks/app_store";
import EULAInput from "./eula_input";
import LoginButton from "./login_button";
import "./page_login.scss";
import PasswordInput from "./password_input";
import UsernameInput from "./username_input";

export default function LoginPage(): JSX.Element {
  const [t] = useTranslation();
  const { auth } = useApp();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isAgreed, setAgreed] = useState(false);

  return useObserver(() => (
    <IonContent>
      <form
        className="eco-login"
        onSubmit={(evt) => {
          localStorage.setItem("wasLoggedIn", "true");
          auth.login(username, password);
          evt.preventDefault();
        }}
      >
        <div className="eco-logo">
          <img src={banner} width="100%" />
        </div>
        <div className="eco-inputs">
          <UsernameInput value={username} onChange={setUsername} />
          <PasswordInput value={password} onChange={setPassword} />
          <EULAInput onChanged={setAgreed} />
        </div>
        <div className="eco-button">
          <IonButton
            size="large"
            expand="block"
            onClick={() =>
              openExternal("https://my.geotab.com/resetPassword.html")
            }
          >
            {t("login.reset_password")}
          </IonButton>

          <LoginButton
            disabled={!isAgreed}
            username={username}
            password={password}
          />
        </div>
      </form>
    </IonContent>
  ));
}
