import React from "react";
import ReactDOM from "react-dom";
import "reflect-metadata";
import App from "./app";
import * as serviceWorker from "./serviceWorker";

window.onunhandledrejection = console.error;

console.log(`
██╗░░██╗███████╗██╗░░░░░██╗░░░░░░█████╗░  ████████╗██╗░░██╗███████╗██████╗░███████╗
██║░░██║██╔════╝██║░░░░░██║░░░░░██╔══██╗  ╚══██╔══╝██║░░██║██╔════╝██╔══██╗██╔════╝
███████║█████╗░░██║░░░░░██║░░░░░██║░░██║  ░░░██║░░░███████║█████╗░░██████╔╝█████╗░░
██╔══██║██╔══╝░░██║░░░░░██║░░░░░██║░░██║  ░░░██║░░░██╔══██║██╔══╝░░██╔══██╗██╔══╝░░
██║░░██║███████╗███████╗███████╗╚█████╔╝  ░░░██║░░░██║░░██║███████╗██║░░██║███████╗
╚═╝░░╚═╝╚══════╝╚══════╝╚══════╝░╚════╝░  ░░░╚═╝░░░╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚══════╝

* email: me@maar.vin
* github: github.com/mcountryman
`);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
