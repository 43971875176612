import { IonItem, IonLabel } from "@ionic/react";
import React from "react";
import openExternal from "../../core/utils/external";

interface ILicenseItemProps {
  name: string;
  kind: string;
  href: string;
}

export default function LicenseItem(props: ILicenseItemProps): JSX.Element {
  return (
    <IonItem button onClick={() => openExternal(props.href)}>
      <IonLabel>
        <h2>{props.name}</h2>
        <p>
          {props.kind} - {props.href}
        </p>
      </IonLabel>
    </IonItem>
  );
}
